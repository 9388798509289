import {
  Badge,
  Button,
  Card,
  Group,
  Loader,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { graphql } from "gql.tada";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { IconChartBar, IconMoneybag } from "@tabler/icons-react";

const ORGANIZATION_PAYMENT_COLLECTION_CONFIGURATION = graphql(`
  query organizationPaymentCollectionConfiguration {
    organizationPaymentCollectionConfiguration {
      wellPlayedFeePercent
      wellPlayedMinimalFee
    }
  }
`);

const ORGANIZATION_PAYMENT_COLLECTION_CREATE_OR_UPDATE_ACCOUNT = graphql(`
  mutation organizationPaymentCollectionConfigurationCreateOrUpdateAccount {
    organizationPaymentCollectionConfigurationCreateOrUpdateAccount
  }
`);

export const SellerSettings = () => {
  const { loading, data } = useQuery(
    ORGANIZATION_PAYMENT_COLLECTION_CONFIGURATION,
  );
  const [configureAccount] = useMutation(
    ORGANIZATION_PAYMENT_COLLECTION_CREATE_OR_UPDATE_ACCOUNT,
  );
  const [configureLoading, setConfigureLoading] = useState(false);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Title>Organization seller settings</Title>
      <SimpleGrid cols={2}>
        <Card pt={0}>
          {
            <>
              <Card.Section>
                <Group position="apart" p={10}>
                  <Group spacing={10}>
                    <IconChartBar />
                    <Text size="md" weight={700}>
                      Your organization's seller settings
                    </Text>
                  </Group>
                  <Badge
                    color={
                      data?.organizationPaymentCollectionConfiguration
                        ? "green"
                        : "red"
                    }
                  >
                    {data?.organizationPaymentCollectionConfiguration
                      ? "Configured"
                      : "Not configured"}
                  </Badge>
                </Group>
              </Card.Section>
              <Stack>
                <Text>
                  The seller settings page is where you can configure your
                  organization's seller settings.
                  <br />
                  We use Stripe Connect to allow your organization to receive
                  payments from buyers.
                  <br />
                  It is required to have configured your Seller settings to
                  start selling products on the platform.
                </Text>
                {(data?.organizationPaymentCollectionConfiguration && (
                  <Button
                    loading={configureLoading}
                    onClick={async () => {
                      setConfigureLoading(true);
                      try {
                        const result = await configureAccount();

                        if (
                          !result.data
                            ?.organizationPaymentCollectionConfigurationCreateOrUpdateAccount
                        ) {
                          return;
                        }

                        // Open new window with Stripe Connect URL
                        window.open(
                          result.data
                            .organizationPaymentCollectionConfigurationCreateOrUpdateAccount,
                        );
                      } finally {
                        setConfigureLoading(false);
                      }
                    }}
                  >
                    Update seller settings
                  </Button>
                )) || (
                  <Button
                    loading={configureLoading}
                    onClick={async () => {
                      setConfigureLoading(true);
                      try {
                        const result = await configureAccount();

                        if (
                          !result.data
                            ?.organizationPaymentCollectionConfigurationCreateOrUpdateAccount
                        ) {
                          return;
                        }

                        window.location.href =
                          result.data.organizationPaymentCollectionConfigurationCreateOrUpdateAccount;
                      } finally {
                        setConfigureLoading(false);
                      }
                    }}
                  >
                    Configure seller settings
                  </Button>
                )}
              </Stack>
            </>
          }
        </Card>
        {data?.organizationPaymentCollectionConfiguration && (
          <Card>
            <Card.Section>
              <Group position="apart" p={10}>
                <Group spacing={10}>
                  <IconMoneybag />
                  <Text size="md" weight={700}>
                    Your current deal
                  </Text>
                </Group>
              </Group>
            </Card.Section>
            <SimpleGrid cols={2}>
              <Stack spacing={0}>
                <Text weight={700}>Fee percent</Text>
                <Text>
                  {data.organizationPaymentCollectionConfiguration
                    .wellPlayedFeePercent * 100}
                  %
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Text weight={700}>Minimal fee</Text>
                <Text>
                  {data.organizationPaymentCollectionConfiguration
                    .wellPlayedMinimalFee / 100}
                  $
                </Text>
              </Stack>
            </SimpleGrid>
          </Card>
        )}
      </SimpleGrid>
    </>
  );
};
