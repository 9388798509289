import { Buffer } from "buffer";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
window.Buffer = Buffer;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import elliptic from "elliptic";

const ec = new elliptic.ec("secp256k1");

const IV_LENGTH = 16;
const AES_KEY_SIZE = 32; // AES-256-CBC requires 32 bytes

export const decryptData = async <T>(
  encryptedString: string,
  base64PrivateKey: string,
): Promise<T> => {
  try {
    // Convert Base64 Encrypted Data to Uint8Array
    const buffer = new Uint8Array(
      atob(encryptedString)
        .split("")
        .map((c) => c.charCodeAt(0)),
    );

    const ephemeralPublicKey = buffer.slice(0, 33);
    const iv = buffer.slice(33, 33 + IV_LENGTH);
    const encryptedData = buffer.slice(33 + IV_LENGTH);

    // Convert Private Key from Base64 to Hex
    const privateKeyHex = Buffer.from(base64PrivateKey, "base64").toString(
      "hex",
    );

    const privateKeyEC = ec.keyFromPrivate(privateKeyHex);
    const ephemeralKeyEC = ec.keyFromPublic(ephemeralPublicKey, "hex");

    // Generate Shared Secret
    let sharedSecret = privateKeyEC
      .derive(ephemeralKeyEC.getPublic())
      .toArrayLike(Uint8Array, "be", 32);

    sharedSecret = await window.crypto.subtle.digest("SHA-256", sharedSecret);
    const aesKey = new Uint8Array(sharedSecret).slice(0, AES_KEY_SIZE); // Slice to 32 bytes

    const aesKeyImported = await window.crypto.subtle.importKey(
      "raw",
      aesKey,
      { name: "AES-CBC" },
      false,
      ["decrypt"],
    );

    const decryptedBuffer = await window.crypto.subtle.decrypt(
      { name: "AES-CBC", iv },
      aesKeyImported,
      encryptedData,
    );

    return JSON.parse(new TextDecoder().decode(decryptedBuffer));
  } catch (error) {
    console.error("❌ Decryption Failed:", error);
    throw new Error("Decryption error: Invalid data or key mismatch");
  }
};
