import { Box, Burger, createStyles, Drawer, Group } from "@mantine/core";
import { NavbarConfiguration, NavbarSearch } from "./navbar/Navbar";
import { Outlet } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("xs")]: {
      display: "none",
    },
  },
}));

export interface ServiceWithMenuLayerProps {
  navbarConfiguration: NavbarConfiguration;
}

export const ServiceWithMenuLayer = ({
  navbarConfiguration,
}: ServiceWithMenuLayerProps) => {
  const { classes, theme } = useStyles();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <Group style={{ zIndex: 0, position: "relative" }}>
      <Burger
        opened={drawerOpened}
        onClick={toggleDrawer}
        className={classes.hiddenDesktop}
      />
      <div className={classes.hiddenMobile}>
        <NavbarSearch configuration={navbarConfiguration} />
      </div>
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <NavbarSearch
          onLinkClick={() => closeDrawer()}
          configuration={navbarConfiguration}
        />
      </Drawer>
      <Box
        sx={{
          minWidth: "100%",
        }}
      >
        <Outlet />
      </Box>
    </Group>
  );
};
