import { useQuery } from "@apollo/client";
import { Center, Group, Loader, Stack, Text, Title } from "@mantine/core";
import { graphql } from "gql.tada";
import { useParams } from "react-router-dom";
import { Scanner } from "@yudiel/react-qr-scanner";
import { decryptData } from "../../utils/encryption.helpers";
import { modals } from "@mantine/modals";
import { IconAlertCircle, IconCheck } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";

const EVENT_DETAILS = graphql(`
  query eventDetails($id: ID!) {
    event(id: $id) {
      title
      decryptionKey
    }
  }
`);

export const EventQRScanner = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const eventId = useParams().eventId!;
  const { data, loading, error } = useQuery(EVENT_DETAILS, {
    variables: { id: eventId },
  });

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    return (
      <Center>
        <Title>Error: {error.message}</Title>
      </Center>
    );
  }

  if (!data?.event) {
    return (
      <Center>
        <Text color="red">Event not found</Text>
      </Center>
    );
  }

  return (
    <Stack spacing={0}>
      <Title>QR Scanner for {data.event.title}</Title>
      <Scanner
        onError={(error) => {
          console.error(error);
          notifications.show({
            id: "qr-scanner-error",
            title: "Error",
            message: "Error scanning QR code",
            color: "red",
          });
        }}
        onScan={async (result) => {
          console.log("rawResult", result);
          try {
            const decryptedData = await decryptData<{
              ticketId: string;
              eventId: string;
              username: string;
            }>(result[0].rawValue, data.event.decryptionKey);
            console.log("decryptedData", decryptedData);

            if (decryptedData.eventId !== eventId) {
              modals.open({
                id: "invalid-ticket",
                title: "Invalid ticket",
                children: (
                  <Group spacing="xs">
                    <IconAlertCircle color="red" />
                    <Text color="red">
                      Ticket has a valid decryption key, but it is not
                      registered for this event. This should not happen.
                    </Text>
                  </Group>
                ),
              });
            }

            modals.open({
              id: "valid-ticket",
              title: "Ticket valid for event",
              children: (
                <Stack spacing={0}>
                  <Group spacing="xs">
                    <IconCheck color="green" />
                    <Text color="green">
                      Ticket is valid for event {data.event.title}
                    </Text>
                  </Group>
                  <Text>
                    <Text span fw={700}>
                      Username:
                    </Text>{" "}
                    {decryptedData.username}
                  </Text>
                  <Text>
                    <Text span fw={700}>
                      Ticket ID:
                    </Text>{" "}
                    {decryptedData.ticketId}
                  </Text>
                  <Text>
                    <Text span fw={700}>
                      Event ID:
                    </Text>{" "}
                    {decryptedData.eventId}
                  </Text>
                </Stack>
              ),
            });
          } catch (error) {
            modals.open({
              id: "invalid-ticket",
              title: "Invalid ticket",
              children: (
                <Group spacing="xs">
                  <IconAlertCircle color="red" />
                  <Text color="red">Ticket is not valid for this event</Text>
                </Group>
              ),
            });
            console.error(error);
          }
        }}
      />
    </Stack>
  );
};
