import { graphql } from "gql.tada";
import { AutoDataTable } from "../../components/AutoDataTable";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  ActionIcon,
  Image,
  Loader,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconTicket, IconUsers } from "@tabler/icons-react";
import {
  Event,
  EventReservationStatusAfterRegistration,
} from "../../../../gql/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { CreationForm } from "../../components/form/CreationForm";
import * as yup from "yup";
import { notifications } from "@mantine/notifications";
import Diagram from "../../../../assets/EventsStatusFlow.svg";
import { IconQrcode } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { IconAlertCircle } from "@tabler/icons-react";

const EVETS_QUERY = graphql(`
  query events($cursor: String, $count: Float) {
    events(
      query: {
        orderBy: REGISTRATIONS_START_AT
        orderDirection: DESC
        showNotVisible: true
        status: ALL
      }
      page: { after: $cursor, first: $count }
    ) {
      nodes {
        id
        title
        startAt
        startRegistrationsAt
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

const EVENT_QUERY = graphql(`
  query event($id: ID!) {
    event(id: $id) {
      id
      title
      description
      startAt
      endAt
      visibleAt
      eventVenueId
    }
  }
`);

const EVENT_REGISTRATION_QUERY = graphql(`
  query eventRegistration($id: ID!) {
    event(id: $id) {
      startRegistrationsAt
      endRegistrationsAt
      configuration {
        sessionDuration
        maxGroupSize
        reservationStatusAfterRegistration
      }
    }
  }
`);

const CREATE_EVENT_MUTATION = graphql(`
  mutation eventCreate(
    $title: String!
    $description: String!
    $startAt: DateTime!
    $endAt: DateTime!
    $eventVenueId: ID
  ) {
    eventCreate(
      input: {
        title: $title
        description: $description
        startAt: $startAt
        endAt: $endAt
        eventVenueId: $eventVenueId
      }
    ) {
      id
    }
  }
`);

const UPDATE_EVENT_MUTATION = graphql(`
  mutation eventUpdate(
    $id: ID!
    $title: String!
    $description: String!
    $startAt: DateTime!
    $endAt: DateTime!
    $visibleAt: DateTime
    $eventVenueId: ID
  ) {
    eventUpdate(
      id: $id
      input: {
        title: $title
        description: $description
        startAt: $startAt
        endAt: $endAt
        visibleAt: $visibleAt
        eventVenueId: $eventVenueId
      }
    ) {
      id
    }
  }
`);

const UPDATE_EVENT_REGISTRATION_MUTATION = graphql(`
  mutation eventUpdateRegistration(
    $id: ID!
    $configuration: EventConfigurationInput!
    $startRegistrationsAt: DateTime!
    $endRegistrationsAt: DateTime!
  ) {
    eventUpdate(
      id: $id
      input: {
        startRegistrationsAt: $startRegistrationsAt
        endRegistrationsAt: $endRegistrationsAt
        configuration: $configuration
      }
    ) {
      id
    }
  }
`);

export const UpdateEventReservations = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const eventId = useParams().eventId!;
  const { loading, data } = useQuery(EVENT_REGISTRATION_QUERY, {
    variables: { id: eventId },
    skip: !eventId,
  });
  const [updateEvent] = useMutation(UPDATE_EVENT_REGISTRATION_MUTATION);

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title="Update event reservations settings"
      schemaDefinition={{
        startRegistrationsAt: {
          type: "INPUT",
          label: "Registrations open at",
          yupConfig: yup.date().required(),
          inputType: "date",
          placeholder: "Enter the event reservations open date",
          defaultValue: data?.event?.startRegistrationsAt,
        },
        endRegistrationsAt: {
          type: "INPUT",
          label: "Registrations closes at",
          yupConfig: yup.date().required(),
          inputType: "date",
          placeholder: "Enter the event reservations ending date",
          defaultValue: data?.event?.endRegistrationsAt,
        },
        sessionDuration: {
          type: "INPUT",
          label: "Session duration",
          yupConfig: yup.number().required(),
          inputType: "number",
          placeholder: "Enter the event session duration",
          info: "The duration of a reservation session in seconds, default is 900 (15 minutes)",
          defaultValue: data?.event?.configuration?.sessionDuration,
        },
        /*maxGroupSize: {
          type: "INPUT",
          label: "Max group size",
          yupConfig: yup.number().required(),
          inputType: "number",
          placeholder: "Enter the event max group size",
          info: "The maximum size of a group, default is 1",
          defaultValue: data?.event?.configuration?.maxGroupSize,
          disabled: true,
        },*/
        reservationStatusAfterRegistration: {
          type: "SELECT",
          label: "Reservation status after payment",
          yupConfig: yup.string().required(),
          options: [
            {
              label: "Confirmed",
              value: EventReservationStatusAfterRegistration.Confirmed,
            },
            {
              label: "Awaiting for presence confirmation",
              value:
                EventReservationStatusAfterRegistration.PresenceConfirmationPending,
            },
            {
              label: "Registered",
              value: EventReservationStatusAfterRegistration.Registered,
            },
          ],
          defaultValue:
            data?.event?.configuration?.reservationStatusAfterRegistration,
        },
      }}
      onSubmit={async (values) => {
        await updateEvent({
          variables: {
            id: eventId,
            startRegistrationsAt: values.startRegistrationsAt,
            endRegistrationsAt: values.endRegistrationsAt,
            configuration: {
              reservationStatusAfterRegistration:
                values.reservationStatusAfterRegistration,
              sessionDuration: values.sessionDuration,
            },
          },
        }).then((data) => {
          if (data?.errors && data.errors.length > 0) {
            throw new Error(data.errors[0].message);
          }
        });
        notifications.show({
          title: "Event updated",
          message: "The event has been updated",
          color: "green",
          autoClose: 3000,
        });
      }}
    />
  );
};

const VENUES_QUERY = graphql(`
  query eventVenues($pageInfo: PageInfo!) {
    eventVenues(page: $pageInfo) {
      nodes {
        id
        name
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const CreateOrUpdateEvent = () => {
  const navigate = useNavigate();
  const eventId = useParams().eventId;
  const { loading, data } = useQuery(EVENT_QUERY, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: eventId! },
    skip: !eventId,
  });
  const [createEvent] = useMutation(CREATE_EVENT_MUTATION);
  const [updateEvent] = useMutation(UPDATE_EVENT_MUTATION);
  const { loading: venuesLoading, data: venuesData } = useQuery(VENUES_QUERY, {
    variables: { pageInfo: { after: null, first: 100 } },
  });

  if (loading || venuesLoading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title={eventId ? "Update event" : "Create event"}
      schemaDefinition={{
        title: {
          type: "INPUT",
          label: "Title",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: data?.event?.title,
          placeholder: "Enter the event title",
        },
        description: {
          type: "RICH_TEXT_EDITOR",
          label: "Description contents",
          yupConfig: yup.string().required(),
          defaultValue: data?.event?.description,
        },
        startAt: {
          type: "INPUT",
          label: "Start at",
          yupConfig: yup.date().required(),
          inputType: "date",
          placeholder: "Enter the event start date",
          defaultValue: data?.event?.startAt,
        },
        endAt: {
          type: "INPUT",
          label: "End at",
          yupConfig: yup.date().required(),
          inputType: "date",
          placeholder: "Enter the event end date",
          defaultValue: data?.event?.endAt,
        },
        ...(eventId && {
          visibleAt: {
            type: "INPUT",
            label: "Visible at",
            yupConfig: yup.date(),
            inputType: "date",
            placeholder:
              "Enter the date when the event will be visible to the public",
            defaultValue: data?.event?.visibleAt,
          },
        }),
        ...(venuesData?.eventVenues.nodes.length && {
          eventVenueId: {
            type: "SELECT",
            label: "Venue",
            yupConfig: yup.string().required(),
            options:
              venuesData?.eventVenues.nodes.map((venue) => ({
                label: venue.name,
                value: venue.id,
              })) ?? [],
            defaultValue: data?.event?.eventVenueId,
          },
        }),
      }}
      onSubmit={async (values) => {
        if (eventId) {
          if (values.eventVenueId !== data?.event?.eventVenueId) {
            const confirmed = await new Promise<boolean>((resolve) =>
              modals.openConfirmModal({
                title: "WARNING",
                children: (
                  <Text>
                    <IconAlertCircle color="red" />
                    The venue of the event has been changed, this will unassign
                    all the existing seats from their reservations.
                    <br />
                    The reservation tickets will still be accessible, but they
                    will not be assigned to a seat anymore.
                    <br />
                    New seats are going to be associated to the event after the
                    venue change.
                    <br />
                    <br />
                    Are you sure you want to change the venue of the event?
                  </Text>
                ),
                labels: {
                  confirm: "Yes",
                  cancel: "No",
                },
                onConfirm: () => resolve(true),
                onCancel: () => resolve(false),
              }),
            );

            if (!confirmed) {
              return;
            }
          }

          await updateEvent({
            variables: {
              id: eventId,
              title: values.title,
              description: values.description,
              startAt: values.startAt,
              endAt: values.endAt,
              visibleAt: values.visibleAt,
              eventVenueId: values.eventVenueId,
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
          });
          notifications.show({
            title: "Tournament updated",
            message: "The tournament has been updated",
            color: "green",
            autoClose: 3000,
          });
        } else {
          const eventId = await createEvent({
            variables: {
              title: values.title,
              description: values.description,
              startAt: values.startAt,
              endAt: values.endAt,
              eventVenueId: values.eventVenueId,
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
            return data.data?.eventCreate.id;
          });
          navigate("/events/" + eventId);
        }
      }}
    />
  );
};

export const Events = () => {
  const navigate = useNavigate();

  return (
    <AutoDataTable
      itemPath={".."}
      createButtonText="Create new event"
      deleteMutation={gql`
        mutation ($id: ID!) {
          eventDelete(id: $id)
        }
      `}
      query={EVETS_QUERY}
      columns={[
        {
          accessor: "title",
          title: "Title",
        },
        {
          accessor: "startAt",
          title: "Start date",
          description: "The date when the event starts",
          rawElement: (event: Event) => (
            <>{new Date(event.startAt).toLocaleString()}</>
          ),
        },
        {
          accessor: "startRegistrationsAt",
          title: "Open registrations date",
          description: "The date when the reservations will be open",
          rawElement: (event: Event) => (
            <>{new Date(event.startRegistrationsAt).toLocaleString()}</>
          ),
        },
        {
          accessor: "actions",
          title: "",
          rawElement: (event) => (
            <>
              <Tooltip label="Manage reservations">
                <ActionIcon
                  onClick={() => navigate("../" + event.id + "/reservations")}
                >
                  <IconUsers />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Edit event ticket configurations">
                <ActionIcon
                  onClick={() =>
                    navigate("../" + event.id + "/settings/tickets")
                  }
                >
                  <IconTicket />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="QR code scanner">
                <ActionIcon
                  onClick={() => navigate("../" + event.id + "/qr-scanner")}
                >
                  <IconQrcode />
                </ActionIcon>
              </Tooltip>
            </>
          ),
        },
      ]}
    />
  );
};

export const EventsHome = () => {
  return (
    <Stack>
      <Title>Events</Title>
      <Text>
        The Events feature allows you to create and manage events, venues and
        ticketing systems.
        <br />
        This system have been designed to be as flexible as possible, allowing
        you to create events that can be used for any kind of event, from
        tournaments to concerts.
        <br />
        By combining the Events feature with the Platform feature, you can
        create your own customized website to sell tickets, manage your events
        and more!
      </Text>
      <Text>
        <Title order={2}>Manage user Reservations</Title>
        The reservation system allows you to manage user reservations.
        <br />
        When a user is registered to an event, it is considered as a
        reservation, the reservation contains all the information about the user
        and the tickets bought for this event by the user.
        <br />
        The reservation can have multiple statuses, depending on the
        configuration of the event, the following flow graph shows the possible
        statuses depending on the user actions:
        <Image src={Diagram} alt="Event status flow" />
      </Text>
      <Text>
        <Title order={2}>Reservation rules</Title>
        Reservation rules are a specific configuration part that can be enabled
        to provide very flexible rules that users must meet to create a
        reservation on the event.
        <br />
        The requirements can range from user profile properties values set on a
        user profile to having a specific external account linked to their
        account.
        <br />
        For example, it is possible to create a reservation rule that requires
        that the player has a valid Discord account linked to their account.
        <br />
        The goal here is to provide the most flexible way to easily set
        reservation requirements without having to confirm each reservations
        manually.
      </Text>
      <Text>
        <Title order={2}>Ticket Configurations</Title>
        The ticket configuration is a specific part of event configurations that
        allows you to define the tickets that can be bought for an event.
        <br />
        From there, you can configure the ticket price, the number of tickets
        available, the ticket type and more.
        <br />
        It is also possible to assign tickets to specific seats or areas of the
        venue, allowing you to create a seating plan for your event and allow
        users to select their seats (see venues for more information).
      </Text>
      <Text>
        <Title order={2}>Groups</Title>
        The group feature allows you to configure if the user is able to invite
        other users to their reservation and assign them tickets.
        <br />
        This is useful for events where the user can invite friends to join
        their reservation and buy tickets for them, or even create teams for
        specific activities!
        <br />
        It can also be used in correlation with Reservation rules to only
        confirm reservations where all the users in the group meet the
        requirements.
      </Text>
      <Text>
        <Title order={2}>Custom Fields</Title>
        The custom fields feature allows you to add custom fields to the user
        registration form.
        <br />
        This is useful to collect additional information about the user when
        they register to an event, or to validate that the user meets specific
        requirements to create a reservation.
        <br />
        For example, you can add a custom field to the registration form that
        asks the user for their Age, and then create a reservation rule that
        requires that the user is over 18 years old to create a reservation.
        <br />
        Custom fields are linked to the reservation or to the reservation's
        group members.
      </Text>
      <Text>
        <Title order={2}>Venues</Title>
        The venue feature allows you to create a venue for your event.
        <br />
        From there, you can create a seating plan for your event, assign tickets
        to specific seats or areas, and more.
        <br />
        An editor is provided to create the seating plan, you can create
        sections, rows and seats and assign tickets to them.
        <br />
        The venue feature is useful for events where you want to provide a
        seating plan to the users and allow them to select their seats.
        <br />
        You can also make your venue configuration public to allow other event
        creators to use your venue configuration for their events.
      </Text>
    </Stack>
  );
};
