import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  Center,
  Group,
  Image,
  Loader,
  Menu,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Portal,
  NumberInput,
  TextInput,
} from "@mantine/core";
import { graphql, ResultOf } from "gql.tada";
import { useNavigate, useParams } from "react-router-dom";
import { CreationForm } from "../../../components/form/CreationForm";
import * as yup from "yup";
import { notifications } from "@mantine/notifications";
import { IconHome2, IconMap, IconTrash } from "@tabler/icons-react";
import { useState, useRef, useCallback, useEffect } from "react";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { uploadFileToS3 } from "../../platform/files/PlatformFile";
import { usePaginatedLoadAll } from "../../../../../business/paginated-query.hook";
import { IS_STG } from "../../../../../server-select";
import { IS_DEV } from "../../../../../utils/env";
import { random } from "lodash";
import { modals } from "@mantine/modals";
import { DndProvider, useDrop, XYCoord } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useListState } from "@mantine/hooks";

const VENUE_QUERY = graphql(`
  query eventVenue($id: ID!) {
    eventVenue(id: $id) {
      id
      name
      description
      address
      city
      state
      country
      postalCode
      configuration {
        imageUrl
      }
    }
  }
`);

const CREATE_VENUE_MUTATION = graphql(`
  mutation eventVenueCreate($input: EventVenueCreateInput!) {
    eventVenueCreate(input: $input) {
      id
    }
  }
`);

const UPDATE_VENUE_MUTATION = graphql(`
  mutation eventVenueUpdate($id: ID!, $input: EventVenueUpdateInput!) {
    eventVenueUpdate(id: $id, input: $input) {
      id
    }
  }
`);

export const VenueHome = () => {
  const venueId = useParams().venueId;
  const { data, loading } = useQuery(VENUE_QUERY, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: venueId! },
  });

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <Title>Venue: {data?.eventVenue?.name}</Title>
      <SimpleGrid cols={2}>
        <Card>
          <Card.Section>
            <Group position="apart" p={10}>
              <Group spacing={10}>
                <IconHome2 />
                <Text size="md" weight={700}>
                  {data?.eventVenue?.name}
                </Text>
              </Group>
            </Group>
          </Card.Section>
          <SimpleGrid cols={2}>
            <Stack>
              <Text weight={700}>Address</Text>
              <Text>{data?.eventVenue?.address}</Text>
            </Stack>
            <Stack>
              <Text weight={700}>City</Text>
              <Text>{data?.eventVenue?.city}</Text>
            </Stack>
            <Stack>
              <Text weight={700}>State</Text>
              <Text>{data?.eventVenue?.state}</Text>
            </Stack>
            <Stack>
              <Text weight={700}>Country</Text>
              <Text>{data?.eventVenue?.country}</Text>
            </Stack>
            <Stack>
              <Text weight={700}>Postal code</Text>
              <Text>{data?.eventVenue?.postalCode}</Text>
            </Stack>
          </SimpleGrid>
        </Card>
      </SimpleGrid>
      <Card mt={10}>
        <Card.Section>
          <Group position="apart" p={10}>
            <Group spacing={10}>
              <IconMap />
              <Text size="md" weight={700}>
                Map
              </Text>
            </Group>
          </Group>
        </Card.Section>
        {data?.eventVenue?.configuration?.imageUrl ? (
          <Image
            src={`https://cdn.well-played.gg/${
              IS_STG || IS_DEV ? "stg" : "prod"
            }/${data?.eventVenue?.configuration?.imageUrl}`}
          />
        ) : (
          <Text>No image yet</Text>
        )}
      </Card>
    </>
  );
};

export const CreatOrUpdateVenueSettings = () => {
  const navigate = useNavigate();
  const venueId = useParams().venueId;
  const { loading, data } = useQuery(VENUE_QUERY, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: venueId! },
    skip: !venueId,
  });
  const [create] = useMutation(CREATE_VENUE_MUTATION);
  const [update] = useMutation(UPDATE_VENUE_MUTATION);

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title={venueId ? "Update venue" : "Create venue"}
      schemaDefinition={{
        name: {
          type: "INPUT",
          label: "Name",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: data?.eventVenue?.name,
          placeholder: "Enter the venue name",
        },
        description: {
          type: "RICH_TEXT_EDITOR",
          label: "Description",
          yupConfig: yup.string().required(),
          defaultValue: data?.eventVenue?.description,
        },
        address: {
          type: "INPUT",
          label: "Address",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: data?.eventVenue?.address,
        },
        city: {
          type: "INPUT",
          label: "City",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: data?.eventVenue?.city,
        },
        state: {
          type: "INPUT",
          label: "State",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: data?.eventVenue?.state,
        },
        country: {
          type: "INPUT",
          label: "Country",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: data?.eventVenue?.country,
        },
        postalCode: {
          type: "INPUT",
          label: "Postal code",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: data?.eventVenue?.postalCode,
        },
      }}
      onSubmit={async (values) => {
        if (venueId) {
          await update({
            variables: {
              id: venueId,
              input: values,
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
          });
          notifications.show({
            title: "Venue updated",
            message: "The venue has been updated",
            color: "green",
            autoClose: 3000,
          });
        } else {
          const venueId = await create({
            variables: {
              input: values,
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
            return data.data?.eventVenueCreate.id;
          });
          navigate("/events/venues/" + venueId);
        }
      }}
    />
  );
};

const UPLOAD_URL_MUTATION = graphql(`
  mutation eventVenueRequestImageUpdate($id: ID!, $size: Float!) {
    eventVenueRequestImageUpdate(id: $id, size: $size) {
      fields {
        key
        value
      }
      url
    }
  }
`);

const SEATS_QUERY = graphql(`
  query eventVenueSeats($id: ID!, $page: PageInfo!) {
    eventVenueSeats(venueId: $id, page: $page) {
      nodes {
        id
        name
        seatTypeId
        configuration {
          size
          svgUrl
          position {
            x
            y
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

const VALIDATE_IMAGE_MUTATION = graphql(`
  mutation eventVenueValidateImage($id: ID!) {
    eventVenueValidateImage(id: $id) {
      id
    }
  }
`);

const UPDATE_SEATS_MUTATION = graphql(`
  mutation eventVenueUpdateSeats(
    $id: ID!
    $createSeats: [EventVenueSeatCreateInput!]
    $deleteSeats: [ID!]
    $updateSeats: [EventVenueSeatUpdateInput!]
  ) {
    eventVenueSeatsUpdate(
      venueId: $id
      input: {
        createSeats: $createSeats
        deleteSeats: $deleteSeats
        updateSeats: $updateSeats
      }
    ) {
      createdSeats {
        id
        name
        seatTypeId
        configuration {
          size
          svgUrl
          position {
            x
            y
          }
        }
      }
      deletedSeats {
        id
      }
      updatedSeats {
        id
        name
        seatTypeId
        configuration {
          size
          svgUrl
          position {
            x
            y
          }
        }
      }
    }
  }
`);

const CREATE_SEAT_TYPE_MUTATION = graphql(`
  mutation eventVenueSeatTypeCreate(
    $id: ID!
    $input: EventVenueSeatTypeCreateInput!
  ) {
    eventVenueSeatTypeCreate(venueId: $id, input: $input) {
      id
    }
  }
`);

const DELETE_SEAT_TYPE_MUTATION = graphql(`
  mutation eventVenueSeatTypeDelete($id: ID!) {
    eventVenueSeatTypeDelete(id: $id) {
      id
    }
  }
`);

const UPDATE_SEAT_TYPE_MUTATION = graphql(`
  mutation eventVenueSeatTypeUpdate(
    $id: ID!
    $input: EventVenueSeatTypeUpdateInput!
  ) {
    eventVenueSeatTypeUpdate(id: $id, input: $input) {
      id
    }
  }
`);

const SEAT_TYPES_QUERY = graphql(`
  query eventVenueSeatTypes($id: ID!, $page: PageInfo!) {
    eventVenueSeatTypes(venueId: $id, page: $page) {
      nodes {
        id
        name
        description
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

const SEAT_TYPE_COLORS = [
  "#FF0000",
  "#00FF00",
  "#0000FF",
  "#FFFF00",
  "#FF00FF",
  "#00FFFF",
  "#FFA500",
  "#800080",
  "#008000",
  "#000080",
  "#800000",
  "#808000",
];

const seatTypeColor = (
  seatTypeId: string,
  seatTypesData: ResultOf<
    typeof SEAT_TYPES_QUERY
  >["eventVenueSeatTypes"]["nodes"],
) => {
  const idx = seatTypesData.findIndex((seatType) => seatType.id === seatTypeId);
  return SEAT_TYPE_COLORS[idx % SEAT_TYPE_COLORS.length];
};

const SeatTypeCreateOrUpdateModal = ({
  seatType,
  onSubmit,
}: {
  seatType?: ResultOf<
    typeof SEAT_TYPES_QUERY
  >["eventVenueSeatTypes"]["nodes"][number];
  onSubmit: (values: { name: string; description: string }) => Promise<void>;
}) => {
  return (
    <CreationForm
      title={seatType ? "Update seat type" : "Create seat type"}
      schemaDefinition={{
        name: {
          type: "INPUT",
          label: "Name",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: seatType?.name,
        },
        description: {
          type: "INPUT",
          label: "Description",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: seatType?.description,
        },
      }}
      onSubmit={onSubmit}
    />
  );
};

const DRAG_THRESHOLD = 5; // pixels
const VenueSeatsMapEditor = ({
  seatTypesData,
  seatType,
  seatSize,
  imageUrl,
}: {
  seatTypesData: ResultOf<
    typeof SEAT_TYPES_QUERY
  >["eventVenueSeatTypes"]["nodes"];
  seatType: string;
  seatSize: number;
  imageUrl: string;
}) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const venueId = useParams().venueId;
  const { results: seatsData, loading: seatsLoading } = usePaginatedLoadAll<
    any,
    any,
    ResultOf<typeof SEATS_QUERY>["eventVenueSeats"]["nodes"][number]
  >(SEATS_QUERY, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: venueId! },
  });
  const [updateSeats] = useMutation(UPDATE_SEATS_MUTATION);
  const mouseDownPos = useRef<{ x: number; y: number } | null>(null);
  const [seats, seatsHandlers] = useListState<
    ResultOf<typeof SEATS_QUERY>["eventVenueSeats"]["nodes"][number]
  >([]);
  const [mousePosition, setMousePosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  useEffect(() => {
    seatsHandlers.setState(seatsData);
  }, [seatsData]);

  const moveSeat = useCallback(
    (
      idx: number,
      seat: ResultOf<typeof SEATS_QUERY>["eventVenueSeats"]["nodes"][number],
      newPosition: { x: number; y: number },
    ) => {
      seatsHandlers.setItemProp(idx, "configuration", {
        ...seat.configuration,
        position: {
          x: newPosition.x,
          y: newPosition.y,
        },
      });
    },
    [seats, seatsHandlers],
  );

  const [, drop] = useDrop(
    () => ({
      accept: "SEAT",
      drop(
        item: ResultOf<typeof SEATS_QUERY>["eventVenueSeats"]["nodes"][number],
        monitor,
      ) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const left = Math.round(item.configuration.position.x + delta.x);
        const top = Math.round(item.configuration.position.y + delta.y);
        const idx = seats.findIndex((seat) => seat.id === item.id);
        moveSeat(idx, item, { x: left, y: top });
        return undefined;
      },
    }),
    [moveSeat],
  );

  if (seatsLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  const mousePosPercent = boxRef.current
    ? {
        x:
          ((mousePosition.x - boxRef.current.getBoundingClientRect().left) /
            boxRef.current.getBoundingClientRect().width) *
          100,
        y:
          ((mousePosition.y - boxRef.current.getBoundingClientRect().top) /
            boxRef.current.getBoundingClientRect().height) *
          100,
      }
    : { x: 0, y: 0 };

  return (
    <Box
      ref={boxRef}
      onMouseMove={(e) => {
        setMousePosition({ x: e.clientX, y: e.clientY });
      }}
      onMouseDown={(e) => {
        mouseDownPos.current = { x: e.clientX, y: e.clientY };
      }}
      onClick={async (event) => {
        if (!seatTypesData?.length || !boxRef.current) {
          // Show an error notification
          notifications.show({
            title: "No seat types selected",
            message: "Please create or selecta seat type",
            color: "red",
          });
          return;
        }

        if (mouseDownPos.current) {
          const dx = Math.abs(event.clientX - mouseDownPos.current.x);
          const dy = Math.abs(event.clientY - mouseDownPos.current.y);

          // Only trigger if mouse hasn't moved more than threshold
          if (dx < DRAG_THRESHOLD && dy < DRAG_THRESHOLD) {
            const id = random();
            notifications.show({
              id: `seat-${id}`,
              title: "Creating seat",
              message: "Please wait while we create the seat",
              loading: true,
              autoClose: false,
              withCloseButton: false,
            });

            try {
              const data = await updateSeats({
                variables: {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  id: venueId!,
                  createSeats: [
                    {
                      name: "New seat", // TODO Open modal to set name
                      seatTypeId: seatType ?? seatTypesData[0].id,
                      configuration: {
                        position: {
                          x: mousePosPercent.x,
                          y: mousePosPercent.y,
                        },
                        size: seatSize,
                      },
                    },
                  ],
                },
              }).then((data) => {
                if (data?.errors && data.errors.length > 0) {
                  throw new Error(data.errors[0].message);
                }

                if (!data.data?.eventVenueSeatsUpdate.createdSeats[0]) {
                  throw new Error("No seat created");
                }

                return data.data.eventVenueSeatsUpdate.createdSeats[0];
              });

              notifications.update({
                id: `seat-${id}`,
                title: "Seat created",
                message: "The seat has been created",
                color: "green",
                loading: false,
                autoClose: 3000,
                withCloseButton: true,
              });

              seatsHandlers.append(data);
            } catch (error: any) {
              notifications.hide(`seat-${id}`);
            }
          }
        }
        mouseDownPos.current = null;
      }}
      style={{
        position: "relative",
      }}
    >
      <Image
        src={`https://cdn.well-played.gg/${
          IS_STG || IS_DEV ? "stg" : "prod"
        }/${imageUrl}`}
        alt="Venue Plan"
        style={{
          width: "100%",
          display: "block",
          backgroundColor: "lightgray",
          cursor: "crosshair",
        }}
      />
      {/* Ghost seat overlay under the mouse to help target the seat */}
      <svg
        viewBox={`0 0 100 100`}
        style={{
          width: `${(seatSize ?? 1) * 2}%`,
          height: `${(seatSize ?? 1) * 2}%`,
          position: "absolute",
          pointerEvents: "none",
          top: `${mousePosPercent.y}%`,
          left: `${mousePosPercent.x}%`,
          transform: `translate(-50%, -50%)`,
          opacity: 0.5,
        }}
      >
        <circle
          cx={50}
          cy={50}
          r={50}
          fill={seatTypeColor(seatType, seatTypesData)}
        />
      </svg>
      {seats?.map(
        ({
          id,
          name,
          seatTypeId,
          configuration: {
            position: { x, y },
            size: seatSize,
          },
        }) => (
          <svg
            key={id}
            viewBox={`0 0 100 100`}
            style={{
              width: `${(seatSize ?? 1) * 2}%`,
              height: `${(seatSize ?? 1) * 2}%`,
              position: "absolute",
              top: `${y}%`,
              left: `${x}%`,
              pointerEvents: "none",
              transform: `translate(-50%, -50%)`,
            }}
          >
            <title>{name}</title>
            <Menu>
              <Menu.Target>
                <circle
                  cx={50}
                  cy={50}
                  r={50}
                  fill={seatTypeColor(seatTypeId, seatTypesData)}
                  stroke="black"
                  strokeWidth="1"
                  style={{
                    cursor: "pointer",
                    pointerEvents: "all",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Menu.Target>
              <Portal>
                <Menu.Dropdown>
                  <Menu.Label>{name}</Menu.Label>
                  <Menu.Item
                    style={{
                      cursor: "pointer",
                      pointerEvents: "all",
                    }}
                    onClick={async (e) => {
                      e.stopPropagation();
                      modals.open({
                        id: "update-seat-data",
                        title: "Update seat data",
                        centered: true,
                        children: (
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              const formData = new FormData(e.currentTarget);
                              console.log("submit", "values", formData);
                            }}
                          >
                            <TextInput
                              label="Name"
                              defaultValue={name}
                              onSubmit={async (e) => {
                                console.log("submit2");
                                const notificationId = random();
                                notifications.show({
                                  id: `update-seat-data-${notificationId}`,
                                  title: "Updating seat data",
                                  message:
                                    "Please wait while we update the seat data",
                                  loading: true,
                                });

                                try {
                                  await updateSeats({
                                    variables: {
                                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                      id: venueId!,
                                      updateSeats: [
                                        { id, name: e.currentTarget.value },
                                      ],
                                    },
                                  }).then((data) => {
                                    if (
                                      data?.errors &&
                                      data.errors.length > 0
                                    ) {
                                      throw new Error(data.errors[0].message);
                                    }
                                  });

                                  notifications.update({
                                    id: `update-seat-data-${notificationId}`,
                                    title: "Seat data updated",
                                    message: "The seat data has been updated",
                                    color: "green",
                                  });
                                  seatsHandlers.setItemProp(
                                    seats.findIndex((seat) => seat.id === id),
                                    "name",
                                    e.currentTarget.value,
                                  );
                                  modals.closeAll();
                                } catch (error: any) {
                                  notifications.hide(
                                    `update-seat-data-${notificationId}`,
                                  );
                                }
                              }}
                            />
                          </form>
                        ),
                      });
                    }}
                  >
                    Update seat data
                  </Menu.Item>
                  <Menu.Item
                    color="red"
                    icon={<IconTrash size={14} />}
                    style={{
                      cursor: "pointer",
                      pointerEvents: "all",
                    }}
                    onClick={async (e) => {
                      e.stopPropagation();
                      const notificationId = random();
                      notifications.show({
                        id: `delete-seat-${notificationId}`,
                        title: "Deleting seat",
                        message: "Please wait while we delete the seat",
                        loading: true,
                      });

                      try {
                        await updateSeats({
                          variables: {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            id: venueId!,
                            deleteSeats: [id],
                          },
                        }).then((data) => {
                          if (data?.errors && data.errors.length > 0) {
                            throw new Error(data.errors[0].message);
                          }

                          if (
                            !data.data?.eventVenueSeatsUpdate.deletedSeats[0]
                          ) {
                            throw new Error("No seat deleted");
                          }
                        });

                        notifications.update({
                          id: `delete-seat-${notificationId}`,
                          title: "Seat deleted",
                          message: "The seat has been deleted",
                          color: "green",
                        });

                        const idx = seats.findIndex((seat) => seat.id === id);
                        seatsHandlers.remove(idx);
                      } catch (error: any) {
                        notifications.hide(`delete-seat-${notificationId}`);
                      }
                    }}
                  >
                    Delete
                  </Menu.Item>
                </Menu.Dropdown>
              </Portal>
            </Menu>
          </svg>
        ),
      )}
    </Box>
  );
};

export const VenueSeatsUpdate = () => {
  const venueId = useParams().venueId;
  const { loading, data, refetch } = useQuery(VENUE_QUERY, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: venueId! },
    skip: !venueId,
  });
  const {
    results: seatTypesData,
    loading: seatTypesLoading,
    refetch: refetchSeatTypes,
  } = usePaginatedLoadAll<
    any,
    any,
    ResultOf<typeof SEAT_TYPES_QUERY>["eventVenueSeatTypes"]["nodes"][number]
  >(SEAT_TYPES_QUERY, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: venueId! },
  });
  const [createSeatType] = useMutation(CREATE_SEAT_TYPE_MUTATION);
  const [deleteSeatType] = useMutation(DELETE_SEAT_TYPE_MUTATION);
  const [updateSeatType] = useMutation(UPDATE_SEAT_TYPE_MUTATION);
  const [uploadUrl] = useMutation(UPLOAD_URL_MUTATION);
  const [validateImage] = useMutation(VALIDATE_IMAGE_MUTATION);
  const [image, setImage] = useState<File>();
  const [isDraggingElement, setIsDraggingElement] = useState(false);
  const [seatType, setSeatType] = useState<string>();
  const [seatSize, setSeatSize] = useState<number>(1);
  const [imageLoading, setImageLoading] = useState(false);

  if (loading || seatTypesLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  // TODO add subscriptions instead of refresh

  return (
    <>
      <Title>Venue Seats</Title>
      <Text fs="italic">Update your venue seats here</Text>
      <Card>
        <Dropzone
          mb={10}
          loading={imageLoading}
          accept={IMAGE_MIME_TYPE}
          multiple={false}
          onDrop={(files) => {
            setImageLoading(true);
            setImage(files[0]);
          }}
        >
          <Text align="center">Drop new image here</Text>
        </Dropzone>
        {!data?.eventVenue?.configuration?.imageUrl && (
          <Text color="red">
            You need to upload an image to update the venue seats
          </Text>
        )}
        {data?.eventVenue?.configuration?.imageUrl && (
          <>
            <Text>Select seat type to use:</Text>
            <SegmentedControl
              onChange={(value) => {
                setSeatType(value);
              }}
              data={seatTypesData.map((seatType, idx) => ({
                value: seatType.id,
                label: (
                  <Text>
                    <Text color={seatTypeColor(seatType.id, seatTypesData)}>
                      {seatType.name}
                    </Text>
                  </Text>
                ),
              }))}
            />
            <Button
              onClick={() => {
                modals.open({
                  id: "new-seat-type",
                  children: (
                    <SeatTypeCreateOrUpdateModal
                      onSubmit={async (values) => {
                        const id = random();
                        notifications.show({
                          id: `seat-type-${id}`,
                          title: "Creating seat type",
                          message: "Please wait while we create the seat type",
                          loading: true,
                        });

                        try {
                          await createSeatType({
                            variables: {
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              id: venueId!,
                              input: {
                                ...values,
                                configuration: {},
                              },
                            },
                          }).then((data) => {
                            if (data?.errors && data.errors.length > 0) {
                              throw new Error(data.errors[0].message);
                            }
                          });

                          notifications.update({
                            id: `seat-type-${id}`,
                            title: "Seat type created",
                            message: "The seat type has been created",
                            color: "green",
                          });
                          modals.closeAll();
                          await refetchSeatTypes();
                        } catch (error: any) {
                          notifications.hide(`seat-type-${id}`);
                        }
                      }}
                    />
                  ),
                });
              }}
            >
              New seat type
            </Button>
            <NumberInput
              label={"Seat size"}
              min={1}
              step={0.1}
              precision={2}
              defaultValue={seatSize}
              onChange={(value) => setSeatSize(value === "" ? seatSize : value)}
            />
            <Text fs="italic" mt={10}>
              Click on a seat to create a new seat type.
            </Text>
            <TransformWrapper panning={{ disabled: isDraggingElement }}>
              <TransformComponent>
                <DndProvider backend={HTML5Backend}>
                  <VenueSeatsMapEditor
                    seatSize={seatSize}
                    seatType={seatType ?? seatTypesData[0]?.id}
                    seatTypesData={seatTypesData}
                    imageUrl={data?.eventVenue?.configuration?.imageUrl}
                  />
                </DndProvider>
              </TransformComponent>
            </TransformWrapper>
          </>
        )}
        {image && (
          <>
            <Center mb={10}>
              <Button
                color="green"
                onClick={async () => {
                  try {
                    setImageLoading(true);
                    const data = await uploadUrl({
                      variables: {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        id: venueId!,
                        size: image.size,
                      },
                    }).then((data) => {
                      if (data?.errors && data.errors.length > 0) {
                        throw new Error(data.errors[0].message);
                      }
                      return data.data?.eventVenueRequestImageUpdate;
                    });

                    if (!data) {
                      throw new Error("No data");
                    }

                    const response = await uploadFileToS3(
                      {
                        fields: data.fields,
                        url: data.url as string,
                      },
                      image,
                    );
                    if (!response.ok) {
                      notifications.show({
                        title: "Failed to upload image",
                        message: "Please try again",
                        color: "red",
                      });
                      return;
                    }

                    await validateImage({
                      variables: {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        id: venueId!,
                      },
                    }).then((data) => {
                      if (data?.errors && data.errors.length > 0) {
                        throw new Error(data.errors[0].message);
                      }
                    });

                    setImage(undefined);

                    notifications.show({
                      title: "Image uploaded",
                      message: "The image has been uploaded",
                      color: "green",
                    });
                    await refetch();
                  } finally {
                    setImageLoading(false);
                  }
                }}
              >
                Apply this image
              </Button>
            </Center>
            <TransformWrapper>
              <TransformComponent>
                <Image
                  src={URL.createObjectURL(image)}
                  onLoad={() => setImageLoading(false)}
                />
              </TransformComponent>
            </TransformWrapper>
          </>
        )}
      </Card>
    </>
  );
};
