import { graphql } from "gql.tada";
import {
  Badge,
  Card,
  Center,
  Group,
  Loader,
  RingProgress,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconCalendarEvent,
  IconTicket,
  IconUserPlus,
} from "@tabler/icons-react";
import { AbbrevitatedNumber } from "../../components/AbbrevitatedNumber";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

const EVENT_DETAILS = graphql(`
  query eventDetails($id: ID!) {
    event(id: $id) {
      title
      startAt
      endAt
      startRegistrationsAt
      endRegistrationsAt
      visibleAt
    }

    eventTicketConfigurations(eventId: $id) {
      name
      currentCount
      maxCount
    }
  }
`);

export const EventDashboard = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const eventId = useParams().eventId!;
  const { loading, data } = useQuery(EVENT_DETAILS, {
    variables: { id: eventId },
  });

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  const totalTicketsCount =
    data?.eventTicketConfigurations.reduce(
      (acc, { maxCount }) => acc + maxCount,
      0,
    ) ?? 0;
  const totalTicketsReserved =
    data?.eventTicketConfigurations.reduce(
      (acc, { currentCount }) => acc + currentCount,
      0,
    ) ?? 0;
  return (
    <>
      <Group>
        <Title>Dashboard</Title>
      </Group>
      <SimpleGrid cols={2}>
        <Card pt={0}>
          {
            <>
              <Card.Section>
                <Group position="apart" p={10}>
                  <Group spacing={10}>
                    <IconCalendarEvent />
                    <Text size="md" weight={700}>
                      {data?.event?.title}
                    </Text>
                  </Group>
                  {data?.event?.visibleAt ? (
                    new Date(data?.event?.visibleAt as string) <=
                      new Date() && <Badge>Visible</Badge>
                  ) : (
                    <Tooltip
                      label={
                        (!data?.event?.visibleAt && (
                          <Text size="sm">No visibility date set yet</Text>
                        )) || (
                          <Text size="sm">
                            Visible at{" "}
                            {new Date(
                              data?.event?.visibleAt as string,
                            ).toLocaleString()}
                          </Text>
                        )
                      }
                    >
                      <Badge color="red">Not visible</Badge>
                    </Tooltip>
                  )}
                </Group>
              </Card.Section>
              <SimpleGrid cols={2}>
                <Stack>
                  <Text weight={700}>Starting date</Text>
                  <Text>
                    {data?.event?.startAt ? (
                      <Text>
                        {new Date(
                          data?.event.startAt as string,
                        ).toLocaleString()}
                      </Text>
                    ) : (
                      <Text>No starting date set yet</Text>
                    )}
                  </Text>
                </Stack>
                <Stack>
                  <Text weight={700}>Ending date</Text>
                  <Text>
                    {data?.event?.endAt ? (
                      <Text>
                        {new Date(
                          data?.event?.endAt as string,
                        ).toLocaleString()}
                      </Text>
                    ) : (
                      <Text>No ending date set yet</Text>
                    )}
                  </Text>
                </Stack>
              </SimpleGrid>
            </>
          }
        </Card>
        <Card pt={0}>
          {
            <>
              <Card.Section>
                <Group position="apart" p={10}>
                  <Group spacing={10}>
                    <IconUserPlus />
                    <Text size="md" weight={700}>
                      Registrations
                    </Text>
                  </Group>
                  {data?.event?.startRegistrationsAt &&
                  new Date(data?.event?.startRegistrationsAt as string) <=
                    new Date() &&
                  (!data?.event?.endRegistrationsAt ||
                    new Date(data?.event?.endRegistrationsAt as string) >
                      new Date()) ? (
                    <Badge>Open</Badge>
                  ) : data?.event?.endRegistrationsAt &&
                    new Date(data?.event?.endRegistrationsAt as string) <=
                      new Date() ? (
                    <Badge color="red">Closed</Badge>
                  ) : data?.event?.startRegistrationsAt ? (
                    <Badge color="orange">Not opened yet</Badge>
                  ) : (
                    <Badge color="red">Not configured</Badge>
                  )}
                </Group>
              </Card.Section>
              <SimpleGrid cols={2}>
                <Stack>
                  <Text weight={700}>Registrations starting date</Text>
                  <Text>
                    {data?.event?.startRegistrationsAt ? (
                      <Text>
                        {new Date(
                          data?.event?.startRegistrationsAt as string,
                        ).toLocaleString()}
                      </Text>
                    ) : (
                      <Text>No registrations starting date set yet</Text>
                    )}
                  </Text>
                </Stack>
                <Stack>
                  <Text weight={700}>Registrations ending date</Text>
                  <Text>
                    {data?.event?.endRegistrationsAt ? (
                      <Text>
                        {new Date(
                          data?.event?.endRegistrationsAt as string,
                        ).toLocaleString()}
                      </Text>
                    ) : (
                      <Text>No registrations ending date set yet</Text>
                    )}
                  </Text>
                </Stack>
              </SimpleGrid>
            </>
          }
        </Card>
        <Card pt={0}>
          {
            <>
              <Stack align="center" spacing={2}>
                <RingProgress
                  sections={[
                    {
                      value: (totalTicketsReserved / totalTicketsCount) * 100,
                      color: "blue",
                    },
                  ]}
                  label={
                    <Center>
                      <AbbrevitatedNumber value={totalTicketsReserved} />
                    </Center>
                  }
                />
                <Text weight={700}>Reserved tickets</Text>
                <Text>
                  <AbbrevitatedNumber value={totalTicketsReserved} /> /{" "}
                  <AbbrevitatedNumber value={totalTicketsCount} />
                </Text>
              </Stack>
            </>
          }
        </Card>
        <Card>
          <Card.Section>
            <Group position="apart" p={10}>
              <Group spacing={10}>
                <IconTicket />
                <Text size="md" weight={700}>
                  Tickets available
                </Text>
              </Group>
            </Group>
          </Card.Section>
          <SimpleGrid cols={2}>
            {data?.eventTicketConfigurations.map(
              ({ name, currentCount, maxCount }) => (
                <Stack key={name}>
                  <Text weight={700}>{name}</Text>
                  <Text>
                    <AbbrevitatedNumber value={currentCount} /> /{" "}
                    <AbbrevitatedNumber value={maxCount} />
                  </Text>
                </Stack>
              ),
            )}
          </SimpleGrid>
        </Card>
      </SimpleGrid>
    </>
  );
};
