import { graphql } from "gql.tada";
import { AutoDataTable } from "../../components/AutoDataTable";
import { Select, Stack, Text } from "@mantine/core";
import { ResultOf } from "@graphql-typed-document-node/core";
import { EventReservationStatus } from "../../../../gql/graphql";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";

const EVENT_RESERVATIONS = graphql(`
  query eventReservations(
    $eventId: ID!
    $cursor: String
    $count: Float
    $query: EventReservationsQueryInput!
  ) {
    eventReservations(
      eventId: $eventId
      query: $query
      page: { after: $cursor, first: $count }
    ) {
      nodes {
        id
        name
        groupId
        status
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

const EVENT_RESERVATION_UPDATE_STATUS = graphql(`
  mutation eventReservationUpdateStatus(
    $reservationId: ID!
    $status: EventReservationStatus!
    $confirmPaymentRefundOrCancel: Boolean
  ) {
    eventReservationUpdateStatus(
      reservationId: $reservationId
      status: $status
      confirmPaymentRefundOrCancel: $confirmPaymentRefundOrCancel
    ) {
      id
    }
  }
`);

export const EventReservations = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const eventId = useParams().eventId!;
  const [status, setStatus] = useState<EventReservationStatus | null>(null);
  const [updateStatus] = useMutation(EVENT_RESERVATION_UPDATE_STATUS);

  return (
    <>
      <Select
        label={"Filter by status"}
        data={Object.values(EventReservationStatus)}
        onChange={(value) => {
          setStatus(value as EventReservationStatus);
        }}
      />
      <AutoDataTable
        createButtonText={false}
        linkClick={(reservation) => {
          alert("Not implemented yet");
          // TODO Open modal to see reservation details
        }}
        query={EVENT_RESERVATIONS}
        queryVariables={{
          eventId,
          query: {
            filterByRegistrationStatus: status,
          },
        }}
        columns={[
          {
            accessor: "name",
            title: "Name",
          },
          {
            accessor: "status",
            title: "Status",
            rawElement: (
              reservation: ResultOf<
                typeof EVENT_RESERVATIONS
              >["eventReservations"]["nodes"][number],
            ) => {
              return (
                <Select
                  data={Object.values(EventReservationStatus)}
                  defaultValue={reservation.status}
                  onChange={async (value) => {
                    await updateStatus({
                      variables: {
                        reservationId: reservation.id,
                        status: value as EventReservationStatus,
                      },
                    }).then(async (data) => {
                      if (data?.errors && data.errors.length > 0) {
                        if (
                          data.errors[0].message?.startsWith(
                            "confirmPaymentRefundOrCancel",
                          )
                        ) {
                          const confirmPromise = new Promise<boolean>(
                            (resolve) => {
                              modals.openConfirmModal({
                                title: "Confirm",
                                children: (
                                  <Stack spacing={0}>
                                    <Text>
                                      You are about to cancel the payment for
                                      this reservation.
                                    </Text>
                                    <Text fw={700}>
                                      This will refund the payment to the user,
                                      this action is irreversible. Are you sure
                                      you want to proceed?
                                    </Text>
                                  </Stack>
                                ),
                                labels: {
                                  cancel: "Cancel",
                                  confirm: "Confirm refund",
                                },
                                confirmProps: { color: "red" },
                                onCancel: () => resolve(false),
                                onConfirm: () => resolve(true),
                              });
                            },
                          );

                          if (await confirmPromise) {
                            await updateStatus({
                              variables: {
                                reservationId: reservation.id,
                                status: value as EventReservationStatus,
                                confirmPaymentRefundOrCancel: true,
                              },
                            }).then((data) => {
                              if (data?.errors && data.errors.length > 0) {
                                throw new Error(data.errors[0].message);
                              }
                            });
                          }
                          return;
                        }
                        throw new Error(data.errors[0].message);
                      }
                    });
                    notifications.show({
                      title: "Success",
                      message: "Reservation status updated",
                      color: "green",
                      autoClose: 3000,
                    });
                  }}
                />
              );
            },
          },
        ]}
      />
    </>
  );
};
