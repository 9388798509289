import { gql, useLazyQuery } from "@apollo/client";
import {
  Autocomplete,
  AutocompleteItem,
  AutocompleteProps,
  Loader,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { Player } from "../../../../gql/graphql";

const SearchPlayerQuery = gql`
  query ($search: String!) {
    searchPlayer(username: $search) {
      id
      username
    }
  }
`;
export const PlayerSearch = ({
  ...props
}: Omit<AutocompleteProps, "data" | "onChange"> & {
  onChange: (value: { username: string; id: string }) => void;
}) => {
  const [searchPlayer] = useLazyQuery<{ searchPlayer: Player[] }>(
    SearchPlayerQuery,
  );
  const [data, setData] = useState<AutocompleteItem[]>([]);
  const [value, setValue] = useState("");
  const [debouncedValue] = useDebouncedValue(value, 500, { leading: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (debouncedValue) {
      setLoading(true);
      searchPlayer({
        variables: {
          search: debouncedValue,
        },
      })
        .then((data) =>
          setData(
            data.data?.searchPlayer.map((p) => ({
              label: p.username,
              value: p.id,
            })) ?? [],
          ),
        )
        .finally(() => setLoading(false));
    }
  }, [debouncedValue]);

  return (
    <Autocomplete
      placeholder="Search player"
      filter={() => true}
      rightSection={loading && <Loader size="xs" />}
      {...props}
      data={data}
      onItemSubmit={(item) => {
        props.onChange({
          username: item.username,
          id: item.value,
        });
      }}
      value={value}
      onChange={(data) => {
        setValue(data);
      }}
    />
  );
};
