import { DataTable } from "mantine-datatable";
import { graphql } from "gql.tada";
import {
  TournamentAdmin,
  TournamentAdminPermissions,
} from "../../../../gql/graphql";
import { AutoDataTable } from "../../components/AutoDataTable";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Checkbox, MultiSelect, Stack } from "@mantine/core";
import { modals } from "@mantine/modals";
import { PlayerSearch } from "../../components/form/PlayerSearch";
import { useState } from "react";

const TournamentAdminsQuery = graphql(`
  query tournamentAdmins($tournamentId: ID!) {
    tournamentAdmins(tournamentId: $tournamentId) {
      tournamentId
      permissions
      account {
        id
        email
        profiles {
          username
        }
      }
    }
  }
`);

const TournamentAdminDeleteMutation = graphql(`
  mutation deleteTournamentAdmin($tournamentId: ID!, $accountId: ID!) {
    tournamentAdminDelete(
      input: { tournamentId: $tournamentId, accountId: $accountId }
    )
  }
`);

const TournamentAdminUpsertMutation = graphql(`
  mutation tournamentAdminUpsert(
    $tournamentId: ID!
    $accountId: ID!
    $permissions: [TournamentAdminPermissions!]!
  ) {
    tournamentAdminUpsert(
      input: {
        tournamentId: $tournamentId
        accountId: $accountId
        permissions: $permissions
      }
    ) {
      accountId
    }
  }
`);

const AddAdminModal = ({ tournamentId }: { tournamentId: string }) => {
  const [selectedPlayer, setSelectedPlayer] = useState<string>();
  const [selectedPermissions, setSelectedPermissions] = useState<
    TournamentAdminPermissions[]
  >([]);
  const [upsert] = useMutation(TournamentAdminUpsertMutation);

  return (
    <>
      <PlayerSearch
        onChange={(value) => {
          setSelectedPlayer(value.id);
        }}
      />
      <MultiSelect
        withinPortal={true}
        data={Object.values(TournamentAdminPermissions)}
        onChange={(value) => {
          setSelectedPermissions(value as TournamentAdminPermissions[]);
        }}
      />
      <Button
        onClick={() =>
          upsert({
            variables: {
              tournamentId,
              accountId: selectedPlayer ?? "",
              permissions: selectedPermissions,
            },
          })
        }
        disabled={!selectedPlayer || selectedPermissions.length === 0}
      >
        Add admin
      </Button>
    </>
  );
};

export const TournamentAdmins = () => {
  const tournamentId = useParams().tournamentId;
  const navigate = useNavigate();

  return (
    <Stack spacing={0}>
      <Button
        w={200}
        onClick={() =>
          modals.open({
            title: "New admin",
            children: <AddAdminModal tournamentId={tournamentId ?? ""} />,
            onClose() {
              navigate(0);
            },
          })
        }
      >
        New admin
      </Button>
      <AutoDataTable
        query={TournamentAdminsQuery}
        deleteMutation={TournamentAdminDeleteMutation}
        queryVariables={{
          tournamentId,
        }}
        createButtonText={false}
        columns={[
          {
            title: "Name/ID",
            accessor: "name",
            rawElement: (row: TournamentAdmin) =>
              row.account.profiles[0]?.username ??
              row.account.email ??
              row.account.id,
          },
          {
            title: "Permissions",
            accessor: "permissions",
            rawElement: (row: TournamentAdmin) => row.permissions.join(", "),
          },
        ]}
      />
    </Stack>
  );
};
