import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Loader, Text, Title } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconAlertCircle } from "@tabler/icons-react";
import { graphql } from "gql.tada";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { Currencies, EventTicketConfiguration } from "../../../../gql/graphql";
import { AutoDataTable } from "../../components/AutoDataTable";
import { CreationForm } from "../../components/form/CreationForm";

const EVENT_TICKETS_QUERY = graphql(`
  query eventTicketConfigurations($id: ID!) {
    eventTicketConfigurations(eventId: $id) {
      id
      price
      currentCount
      maxCount
      currency
      name
      description
      seatTypeId
    }
  }
`);

const EVENT_TICKET_QUERY = graphql(`
  query eventTicketConfiguration($id: ID!) {
    eventTicketConfiguration(id: $id) {
      id
      price
      currency
      name
      description
      maxCount
      seatTypeId
    }
  }
`);

const EVENT_VENUE_SEAT_TYPES_QUERY = graphql(`
  query eventVenueSeatTypes($id: ID!) {
    eventVenueSeatTypes(venueId: $id, page: { first: 100 }) {
      nodes {
        id
        name
      }
    }
  }
`);

const EVENT_VENUE_SEATS_COUNT_QUERY = graphql(`
  query eventVenueSeatsCount($id: ID!, $seatTypeId: ID) {
    eventVenueSeats(venueId: $id, page: { first: 1 }, seatTypeId: $seatTypeId) {
      totalCount
    }
  }
`);

const EVENT_QUERY = graphql(`
  query event($id: ID!) {
    event(id: $id) {
      eventVenueId
    }
  }
`);

const EventTicketUpdateModal = ({
  ticketConfigurationId,
  eventId,
}: {
  ticketConfigurationId?: string;
  eventId: string;
}) => {
  const [updatedMaxCount, setUpdatedMaxCount] = useState<number | null>(null);
  const { loading, data } = useQuery(EVENT_TICKET_QUERY, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: ticketConfigurationId! },
    skip: !ticketConfigurationId,
  });
  const { loading: eventLoading, data: eventData } = useQuery(EVENT_QUERY, {
    variables: { id: eventId },
  });
  const { loading: seatTypesLoading, data: seatTypesData } = useQuery(
    EVENT_VENUE_SEAT_TYPES_QUERY,
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { id: eventData?.event?.eventVenueId ?? "" },
      skip: !eventData?.event?.eventVenueId,
    },
  );
  const [getSeatsCount] = useLazyQuery(EVENT_VENUE_SEATS_COUNT_QUERY);
  const [update] = useMutation(gql`
    mutation ($id: ID!, $input: EventTicketConfigurationUpdateInput!) {
      eventTicketConfigurationUpdate(
        ticketConfigurationId: $id
        input: $input
      ) {
        id
      }
    }
  `);
  const [create] = useMutation(gql`
    mutation ($input: EventTicketConfigurationCreateInput!) {
      eventTicketConfigurationCreate(input: $input) {
        id
      }
    }
  `);

  if (loading || seatTypesLoading || eventLoading) {
    return <Loader />;
  }

  return (
    <CreationForm
      schemaDefinition={{
        name: {
          label: "Name",
          type: "INPUT",
          inputType: "text",
          yupConfig: yup.string().required(),
          defaultValue: data?.eventTicketConfiguration?.name,
        },
        description: {
          label: "Description",
          type: "TEXTAREA",
          yupConfig: yup.string().required(),
          defaultValue: data?.eventTicketConfiguration?.description,
        },
        price: {
          label: "Price",
          type: "INPUT",
          inputType: "number",
          yupConfig: yup.number().required(),
          defaultValue: (data?.eventTicketConfiguration?.price ?? 0) / 100,
        },
        currency: {
          label: "Currency",
          type: "SELECT",
          options: Object.values(Currencies).map((v) => ({
            label: v,
            value: v,
          })),
          yupConfig: yup.string().required(),
          defaultValue: data?.eventTicketConfiguration?.currency,
        },
        maxCount: {
          label: "Max count",
          type: "INPUT",
          inputType: "number",
          yupConfig: yup.number().required(),
          defaultValue: data?.eventTicketConfiguration?.maxCount,
          onChange: (value) => {
            setUpdatedMaxCount(Number(value));
          },
          value: updatedMaxCount,
        },
        seatTypeId: {
          label: "Seat type",
          type: "SELECT",
          options:
            seatTypesData?.eventVenueSeatTypes?.nodes.map((v) => ({
              label: v.name,
              value: v.id,
            })) ?? [],
          yupConfig: yup.string().required(),
          defaultValue: data?.eventTicketConfiguration?.seatTypeId,
          onChange: async (value) => {
            const response = await getSeatsCount({
              variables: {
                id: eventData?.event?.eventVenueId ?? "",
                seatTypeId: value,
              },
            });
            setUpdatedMaxCount(response.data?.eventVenueSeats.totalCount ?? 0);
          },
        },
      }}
      onSubmit={async (datas) => {
        if (ticketConfigurationId) {
          if (datas.seatTypeId !== data?.eventTicketConfiguration?.seatTypeId) {
            const ctnue = await new Promise<boolean>((resolve) => {
              modals.openConfirmModal({
                children: (
                  <Text>
                    <IconAlertCircle color="red" />
                    Are you sure you want to change the seat type? This will
                    affect all reservations for this ticket configuration.
                    <br />
                    The reservation tickets will still be accessible, but they
                    will not be assigned to a seat anymore.
                    <br />
                    New seats are going to be associated to the ticket
                    configuration after the seat type change.
                    <br />
                    <br />
                    Are you sure you want to continue?
                  </Text>
                ),
                labels: {
                  confirm: "Yes",
                  cancel: "No",
                },
                onConfirm: () => resolve(true),
                onCancel: () => resolve(false),
              });
            });
            if (!ctnue) {
              return;
            }
          }

          await update({
            variables: {
              id: ticketConfigurationId,
              input: {
                name: datas.name,
                description: datas.description,
                price: datas.price * 100,
                currency: datas.currency,
                maxCount: datas.maxCount,
                seatTypeId: datas.seatTypeId,
              },
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
          });
        } else {
          await create({
            variables: {
              input: {
                eventId,
                name: datas.name,
                description: datas.description,
                price: datas.price * 100,
                currency: datas.currency,
                maxCount: datas.maxCount,
                seatTypeId: datas.seatTypeId,
              },
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
          });
        }
        modals.closeAll();
      }}
    />
  );
};

export const EventTickets = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const eventId = useParams().eventId!;
  const navigate = useNavigate();

  return (
    <>
      <Title>Ticket configurations</Title>
      <Button
        onClick={() => {
          modals.open({
            children: <EventTicketUpdateModal eventId={eventId} />,
            onClose() {
              navigate(0);
            },
          });
        }}
      >
        Create a new ticket configuration
      </Button>
      <AutoDataTable
        createButtonText={false}
        deleteMutation={gql`
          mutation ($id: ID!) {
            eventTicketConfigurationDelete(ticketConfigurationId: $id) {
              id
            }
          }
        `}
        linkClick={(item) => {
          modals.open({
            children: (
              <EventTicketUpdateModal
                eventId={eventId}
                ticketConfigurationId={item.id}
              />
            ),
            onClose() {
              navigate(0);
            },
          });
        }}
        query={EVENT_TICKETS_QUERY}
        queryVariables={{
          id: eventId,
        }}
        columns={[
          {
            accessor: "name",
            title: "Name",
          },
          {
            accessor: "description",
            title: "Description",
          },
          {
            accessor: "price",
            title: "Price",
            rawElement: (item: EventTicketConfiguration) => (
              <>
                {(item.price / 100).toFixed(2)}
                {item.currency}
              </>
            ),
          },
          {
            accessor: "count",
            title: "Available tickets",
            rawElement: (item) => (
              <>
                {item.currentCount}/{item.maxCount}
              </>
            ),
          },
        ]}
      />
    </>
  );
};
