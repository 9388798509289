/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  /** Integers that will have a value greater than 0. */
  PositiveInt: { input: any; output: any; }
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: any; output: any; }
  /** Represents NULL values */
  Void: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  email?: Maybe<Scalars['EmailAddress']['output']>;
  /** The ID of the account */
  id: Scalars['ID']['output'];
  identities: Array<AccountIdentity>;
  permissions: Array<GroupPermission>;
  profiles: Array<Player>;
};


export type AccountIdentitiesArgs = {
  properties?: InputMaybe<Array<Scalars['String']['input']>>;
  providerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AccountIdentity = {
  __typename?: 'AccountIdentity';
  accountId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  organizationIdentityProvider?: Maybe<PublicOrganizationIdentityProvider>;
  organizationIdentityProviderId: Scalars['ID']['output'];
  properties: Array<AccountIdentityProperty>;
  providerId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AccountIdentityProperty = {
  __typename?: 'AccountIdentityProperty';
  property: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum AggregationType {
  Avg = 'AVG',
  Max = 'MAX',
  Min = 'MIN',
  Sum = 'SUM'
}

export type AutomaticSeedingInput = {
  groupRepartitionMechanism: GroupRepartitionMechanism;
  seedingMechanism: SeedingMechanism;
  teams: Array<Scalars['ID']['input']>;
};

export enum BucketDuration {
  Daily = 'DAILY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type Condition = {
  __typename?: 'Condition';
  numericCondition?: Maybe<NumericCondition>;
  property: Scalars['String']['output'];
  propertyCondition: PropertyCondition;
  stringCondition?: Maybe<StringCondition>;
};

export type ConditionInput = {
  numericCondition?: InputMaybe<NumberConditionInput>;
  property: Scalars['String']['input'];
  propertyCondition: PropertyCondition;
  stringCondition?: InputMaybe<StringConditionInput>;
};

export type Configuration = {
  __typename?: 'Configuration';
  configuration: ConfigurationProperties;
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ConfigurationProperties = StepConfiguration | TournamentConfiguration;

export enum ConfigurationType {
  Step = 'STEP',
  Tournament = 'TOURNAMENT'
}

export type CreateConfigurationInput = {
  configuration: Scalars['JSONObject']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateLeaderboardInput = {
  /** The description of the leaderboard */
  description: Scalars['String']['input'];
  /** The name of the leaderboard */
  name: Scalars['String']['input'];
};

export type CreateLeaderboardSeasonBucketInput = {
  minScore: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  scoreCalculationType: LeaderboardScoreCalculationType;
};

export type CreateLeaderboardSeasonInput = {
  end: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
  startingScore: Scalars['Float']['input'];
};

export type CreateOrganizationAppInput = {
  consentUrl: Scalars['String']['input'];
  description: Scalars['String']['input'];
  loginUrl: Scalars['String']['input'];
  logoutRedirectUrls: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  public?: InputMaybe<Scalars['Boolean']['input']>;
  redirectUrls: Array<Scalars['String']['input']>;
  requiresConsent: Scalars['Boolean']['input'];
  secret?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrganizationGroupInput = {
  name: Scalars['String']['input'];
  permissions: Array<GroupPermissionInput>;
};

export type CreateOrganizationIdentityProvider = {
  allowLogin: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  icon?: InputMaybe<Scalars['String']['input']>;
  identityProviderId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  oauth2Configuration?: InputMaybe<OAuthClientConfigurationInput>;
  openidConfiguration?: InputMaybe<OpenidConfigurationInput>;
};

export type CreateOrganizationInput = {
  configuration: OrganizationConfigurationInput;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreatePlatformInput = {
  configuration?: InputMaybe<PlatformConfigurationInput>;
  name: Scalars['String']['input'];
  subdomain: Scalars['String']['input'];
};

export type CreatePlatformTemplateInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  public: Scalars['Boolean']['input'];
  screenshotUrl: Scalars['URL']['input'];
};

export type CreatePlayerInput = {
  customFields: Array<PlayerCustomFieldInput>;
  description: Scalars['String']['input'];
  organizationCustomId?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  profilePicUrl?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type CreateTournamentInput = {
  /** Tournament configuration */
  configuration?: InputMaybe<UpdateTournamentConfigurationOrImportFromIdInput>;
  /** Tournament description */
  description: Scalars['String']['input'];
  /** Tournament end date */
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Tournament registrations end date */
  endRegistrationsAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Tournament start date */
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Tournament registrations start date */
  startRegistrationsAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Tournament title */
  title: Scalars['String']['input'];
  /** Tournament visibility date, the tournament will be visible from this date in the tournaments list */
  visibleAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateTournamentStepInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Float']['input'];
  properties?: InputMaybe<Array<PropertyValueInput>>;
  type: StepType;
};

export type CreateWebhookInput = {
  active: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  events: Array<WebhookEventType>;
  url: Scalars['String']['input'];
};

export enum Currencies {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type CustomDomainData = {
  __typename?: 'CustomDomainData';
  hostname: Scalars['String']['output'];
  ownership_verification?: Maybe<CustomDomainOwnershipVerification>;
  ssl: CustomDomainSsl;
  status?: Maybe<CustomDomainStatus>;
  verification_errors?: Maybe<Array<Scalars['String']['output']>>;
};

export type CustomDomainOwnershipVerification = {
  __typename?: 'CustomDomainOwnershipVerification';
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CustomDomainSsl = {
  __typename?: 'CustomDomainSsl';
  status?: Maybe<CustomDomainValidationStatus>;
  validation_errors?: Maybe<Array<CustomDomainSslValidationError>>;
  validation_records?: Maybe<Array<CustomDomainSslValidationRecord>>;
};

export type CustomDomainSslValidationError = {
  __typename?: 'CustomDomainSslValidationError';
  message?: Maybe<Scalars['String']['output']>;
};

export type CustomDomainSslValidationRecord = {
  __typename?: 'CustomDomainSslValidationRecord';
  txt_name?: Maybe<Scalars['String']['output']>;
  txt_value?: Maybe<Scalars['String']['output']>;
};

export enum CustomDomainStatus {
  Active = 'active',
  ActiveRedeploying = 'active_redeploying',
  Blocked = 'blocked',
  Deleted = 'deleted',
  Moved = 'moved',
  Pending = 'pending',
  PendingBlocked = 'pending_blocked',
  PendingDeletion = 'pending_deletion',
  PendingMigration = 'pending_migration',
  PendingProvisioned = 'pending_provisioned',
  Provisioned = 'provisioned',
  TestActive = 'test_active',
  TestActiveApex = 'test_active_apex',
  TestBlocked = 'test_blocked',
  TestFailed = 'test_failed',
  TestPending = 'test_pending'
}

export enum CustomDomainValidationStatus {
  Active = 'active',
  BackupIssued = 'backup_issued',
  Deactivating = 'deactivating',
  Deleted = 'deleted',
  DeletionTimedOut = 'deletion_timed_out',
  DeploymentTimedOut = 'deployment_timed_out',
  Expired = 'expired',
  HoldingDeployment = 'holding_deployment',
  Inactive = 'inactive',
  Initializing = 'initializing',
  InitializingTimedOut = 'initializing_timed_out',
  IssuanceTimedOut = 'issuance_timed_out',
  PendingCleanup = 'pending_cleanup',
  PendingDeletion = 'pending_deletion',
  PendingDeployment = 'pending_deployment',
  PendingExpiration = 'pending_expiration',
  PendingIssuance = 'pending_issuance',
  PendingValidation = 'pending_validation',
  StagingActive = 'staging_active',
  StagingDeployment = 'staging_deployment',
  ValidationTimedOut = 'validation_timed_out'
}

export type CustomTeamMemberInput = {
  /** A custom created player, will create a real player profile if set. You should set either player or playerId, not both */
  player?: InputMaybe<CreatePlayerInput>;
  /** An existing player profile ID to use for this team, you have to set either playerId or player, not both */
  playerId?: InputMaybe<Scalars['ID']['input']>;
};

export type DataRetrievalConfig = {
  __typename?: 'DataRetrievalConfig';
  headers: Array<Header>;
  mappingConfiguration: MappingConfiguration;
  url: Scalars['String']['output'];
};

export type DataRetrievalConfigInput = {
  headers: Array<HeaderInput>;
  mappingConfiguration: MappingConfigurationInput;
  url: Scalars['String']['input'];
};

export type DeleteOrganizationAttachedAppInput = {
  appId: Scalars['String']['input'];
};

export type DeleteOrganizationMembershipInput = {
  userId: Scalars['ID']['input'];
};

export type Event = EventInterface & {
  __typename?: 'Event';
  configuration?: Maybe<EventConfiguration>;
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['ID']['output'];
  decryptionKey: Scalars['String']['output'];
  description: Scalars['String']['output'];
  endAt?: Maybe<Scalars['DateTime']['output']>;
  endRegistrationsAt?: Maybe<Scalars['DateTime']['output']>;
  eventVenueId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  startAt?: Maybe<Scalars['DateTime']['output']>;
  startRegistrationsAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** The venue where this event is taking place */
  venue?: Maybe<EventVenue>;
  visibleAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EventConfiguration = {
  __typename?: 'EventConfiguration';
  /** Custom fields that are defined on each teams */
  customFields?: Maybe<Array<Property>>;
  /** Maximum number of users that can join the same group */
  maxGroupSize?: Maybe<Scalars['Float']['output']>;
  /** Maximum number of tickets per reservation */
  maxTicketsPerReservation?: Maybe<Scalars['Float']['output']>;
  /** Condition rules to register to the tournament */
  registrationConditions?: Maybe<RegistrationConditions>;
  /** Status of the reservation after payment validation, REGISTERED is the default state, CONFIRMED means that the registration has been validated by an admin, PRESENCE_CONFIRMATION_PENDING means that the registration is validated but the presence of the users is not confirmed yet */
  reservationStatusAfterRegistration?: Maybe<EventReservationStatusAfterRegistration>;
  /** The number of seconds before registration session expires, default is 15 minutes */
  sessionDuration?: Maybe<Scalars['Float']['output']>;
};

export type EventConfigurationInput = {
  /** Custom fields that are defined on each teams */
  customFields?: InputMaybe<Array<PropertyInput>>;
  /** Maximum number of users that can join the same group */
  maxGroupSize?: InputMaybe<Scalars['Float']['input']>;
  /** Maximum number of tickets per reservation */
  maxTicketsPerReservation?: InputMaybe<Scalars['Float']['input']>;
  /** Condition rules to register to the tournament */
  registrationConditions?: InputMaybe<RegistrationConditionsInput>;
  /** Status of the reservation after payment validation, REGISTERED is the default state, CONFIRMED means that the registration has been validated by an admin, PRESENCE_CONFIRMATION_PENDING means that the registration is validated but the presence of the users is not confirmed yet */
  reservationStatusAfterRegistration?: InputMaybe<EventReservationStatusAfterRegistration>;
  /** The number of seconds before registration session expires, default is 15 minutes */
  sessionDuration?: InputMaybe<Scalars['Float']['input']>;
};

export type EventCreateInput = {
  configuration?: InputMaybe<EventConfigurationInput>;
  description: Scalars['String']['input'];
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  endRegistrationsAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventVenueId?: InputMaybe<Scalars['ID']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  startRegistrationsAt?: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['String']['input'];
  visibleAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EventEdge = {
  __typename?: 'EventEdge';
  cursor: Scalars['String']['output'];
  node: Array<Event>;
};

export type EventInterface = {
  configuration?: Maybe<EventConfiguration>;
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['ID']['output'];
  decryptionKey: Scalars['String']['output'];
  description: Scalars['String']['output'];
  endAt?: Maybe<Scalars['DateTime']['output']>;
  endRegistrationsAt?: Maybe<Scalars['DateTime']['output']>;
  eventVenueId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  startAt?: Maybe<Scalars['DateTime']['output']>;
  startRegistrationsAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** The venue where this event is taking place */
  venue?: Maybe<EventVenue>;
  visibleAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EventReservation = {
  __typename?: 'EventReservation';
  createdAt: Scalars['DateTime']['output'];
  eventId: Scalars['ID']['output'];
  groupId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sessionValidUntil: Scalars['DateTime']['output'];
  status: EventReservationStatus;
  tickets: Array<EventReservationTicket>;
  updatedAt: Scalars['DateTime']['output'];
};

export type EventReservationRegisterTicketInput = {
  quantity: Scalars['Float']['input'];
  reservedSeats: Array<Scalars['ID']['input']>;
  ticketConfigurationId: Scalars['ID']['input'];
};

export type EventReservationRegisterTicketsInput = {
  tickets: Array<EventReservationRegisterTicketInput>;
};

export enum EventReservationStatus {
  Confirmed = 'CONFIRMED',
  Denied = 'DENIED',
  GroupNotValid = 'GROUP_NOT_VALID',
  NotAttending = 'NOT_ATTENDING',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentRefunded = 'PAYMENT_REFUNDED',
  Pending = 'PENDING',
  PresenceConfirmationPending = 'PRESENCE_CONFIRMATION_PENDING',
  Registered = 'REGISTERED',
  SessionExpired = 'SESSION_EXPIRED'
}

export enum EventReservationStatusAfterRegistration {
  Confirmed = 'CONFIRMED',
  PresenceConfirmationPending = 'PRESENCE_CONFIRMATION_PENDING',
  Registered = 'REGISTERED'
}

export type EventReservationTicket = {
  __typename?: 'EventReservationTicket';
  createdAt: Scalars['DateTime']['output'];
  groupMemberId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  reservationId: Scalars['ID']['output'];
  seatId?: Maybe<Scalars['ID']['output']>;
  ticketId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type EventReservationTicketWithQrCode = {
  __typename?: 'EventReservationTicketWithQrCode';
  createdAt: Scalars['DateTime']['output'];
  groupMemberId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  qrCode: Scalars['String']['output'];
  reservationId: Scalars['ID']['output'];
  seatId?: Maybe<Scalars['ID']['output']>;
  ticketId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type EventReservationValidateAndPay = {
  __typename?: 'EventReservationValidateAndPay';
  clientSecret?: Maybe<Scalars['String']['output']>;
  paymentUrl?: Maybe<Scalars['String']['output']>;
};

export type EventReservationValidateAndPayInput = {
  /** If set, Checkout displays a back button and customers will be directed to this URL if they decide to cancel payment and return to your website. This parameter is not allowed if uiMode is 'embedded'. */
  canceledUrl?: InputMaybe<Scalars['String']['input']>;
  /** The URL to redirect your customer back to after they authenticate or cancel their payment on the payment method’s app or site. This parameter is required if uiMode is 'embedded'. */
  returnUrl?: InputMaybe<Scalars['String']['input']>;
  /** The URL to which Stripe should send customers when payment or setup is complete. This parameter is not allowed if uiMode is "embedded". */
  successUrl?: InputMaybe<Scalars['String']['input']>;
  uiMode?: InputMaybe<StripeUiMode>;
};

export type EventReservationWithTicketsAndQrCode = {
  __typename?: 'EventReservationWithTicketsAndQrCode';
  createdAt: Scalars['DateTime']['output'];
  eventId: Scalars['ID']['output'];
  groupId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sessionValidUntil: Scalars['DateTime']['output'];
  status: EventReservationStatus;
  tickets: Array<EventReservationTicketWithQrCode>;
  updatedAt: Scalars['DateTime']['output'];
};

export type EventReservations = {
  __typename?: 'EventReservations';
  edges: Array<OmitObjectTypeEdge>;
  nodes: Array<OmitObjectType>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type EventReservationsQueryInput = {
  filterByPlayerIdentityProviderProperties?: InputMaybe<Array<IdentityProviderPropertyInput>>;
  filterByPlayerProperties?: InputMaybe<Array<PropertyValueInput>>;
  filterByPlayerUsernameQuery?: InputMaybe<Scalars['String']['input']>;
  filterByPlayers?: InputMaybe<Array<EventReservationsQueryPlayerIdWithGroupStatusInput>>;
  filterByRegistrationStatus?: InputMaybe<EventReservationStatus>;
  filterByReservationProperties?: InputMaybe<Array<PropertyValueInput>>;
  retrieveOnlyGroupMembersWithStatus?: InputMaybe<UserGroupMemberStatus>;
};

export type EventReservationsQueryPlayerIdWithGroupStatusInput = {
  memberStatus?: InputMaybe<UserGroupMemberStatus>;
  /** Player id */
  playerId: Scalars['ID']['input'];
};

export type EventSeat = {
  __typename?: 'EventSeat';
  configuration: EventVenueSeatConfiguration;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  reservedByTicketId?: Maybe<Scalars['ID']['output']>;
  seatTypeId: Scalars['ID']['output'];
  status: EventSeatStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type EventSeatEdge = {
  __typename?: 'EventSeatEdge';
  cursor: Scalars['String']['output'];
  node: Array<EventSeat>;
};

export enum EventSeatStatus {
  Available = 'AVAILABLE',
  Reserved = 'RESERVED',
  Sold = 'SOLD'
}

export type EventSeats = {
  __typename?: 'EventSeats';
  edges: Array<EventSeatEdge>;
  nodes: Array<EventSeat>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type EventTicketConfiguration = {
  __typename?: 'EventTicketConfiguration';
  buyable: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Currencies;
  currentCount: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  eventId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  maxCount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  seatTypeId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type EventTicketConfigurationCreateInput = {
  buyable: Scalars['Boolean']['input'];
  currency: Currencies;
  description: Scalars['String']['input'];
  eventId: Scalars['ID']['input'];
  maxCount: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  seatTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type EventTicketConfigurationUpdateInput = {
  buyable?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Currencies>;
  description?: InputMaybe<Scalars['String']['input']>;
  maxCount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  seatTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type EventUpdateInput = {
  configuration?: InputMaybe<EventConfigurationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  endRegistrationsAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventVenueId?: InputMaybe<Scalars['ID']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  startRegistrationsAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  visibleAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EventVenue = {
  __typename?: 'EventVenue';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  configuration: EventVenueConfiguration;
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type EventVenueConfiguration = {
  __typename?: 'EventVenueConfiguration';
  imageUrl?: Maybe<Scalars['String']['output']>;
};

export type EventVenueConfigurationInput = {
  imageUrl?: InputMaybe<Scalars['String']['input']>;
};

export type EventVenueCreateInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type EventVenueEdge = {
  __typename?: 'EventVenueEdge';
  cursor: Scalars['String']['output'];
  node: Array<EventVenue>;
};

export type EventVenueSeat = {
  __typename?: 'EventVenueSeat';
  configuration: EventVenueSeatConfiguration;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  seatTypeId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type EventVenueSeatConfiguration = {
  __typename?: 'EventVenueSeatConfiguration';
  position: Position;
  size?: Maybe<Scalars['Float']['output']>;
  svgUrl?: Maybe<Scalars['String']['output']>;
};

export type EventVenueSeatConfigurationInput = {
  position: PositionInput;
  size?: InputMaybe<Scalars['Float']['input']>;
  svgUrl?: InputMaybe<Scalars['String']['input']>;
};

export type EventVenueSeatCreateInput = {
  configuration: EventVenueSeatConfigurationInput;
  name: Scalars['String']['input'];
  seatTypeId: Scalars['ID']['input'];
};

export type EventVenueSeatEdge = {
  __typename?: 'EventVenueSeatEdge';
  cursor: Scalars['String']['output'];
  node: Array<EventVenueSeat>;
};

export type EventVenueSeatType = {
  __typename?: 'EventVenueSeatType';
  configuration: EventVenueSeatTypeConfiguration;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  venueId: Scalars['ID']['output'];
};

export type EventVenueSeatTypeConfiguration = {
  __typename?: 'EventVenueSeatTypeConfiguration';
  nonce?: Maybe<Scalars['String']['output']>;
};

export type EventVenueSeatTypeConfigurationInput = {
  nonce?: InputMaybe<Scalars['String']['input']>;
};

export type EventVenueSeatTypeCreateInput = {
  configuration: EventVenueSeatTypeConfigurationInput;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type EventVenueSeatTypeEdge = {
  __typename?: 'EventVenueSeatTypeEdge';
  cursor: Scalars['String']['output'];
  node: Array<EventVenueSeatType>;
};

export type EventVenueSeatTypeUpdateInput = {
  configuration?: InputMaybe<EventVenueSeatTypeConfigurationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type EventVenueSeatTypes = {
  __typename?: 'EventVenueSeatTypes';
  edges: Array<EventVenueSeatTypeEdge>;
  nodes: Array<EventVenueSeatType>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type EventVenueSeatUpdateInput = {
  configuration?: InputMaybe<EventVenueSeatConfigurationInput>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  seatTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type EventVenueSeats = {
  __typename?: 'EventVenueSeats';
  edges: Array<EventVenueSeatEdge>;
  nodes: Array<EventVenueSeat>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type EventVenueSeatsUpdateInput = {
  createSeats?: InputMaybe<Array<EventVenueSeatCreateInput>>;
  deleteSeats?: InputMaybe<Array<Scalars['ID']['input']>>;
  updateSeats?: InputMaybe<Array<EventVenueSeatUpdateInput>>;
};

export type EventVenueSeatsUpdateResult = {
  __typename?: 'EventVenueSeatsUpdateResult';
  createdSeats: Array<EventVenueSeat>;
  deletedSeats: Array<EventVenueSeat>;
  updatedSeats: Array<EventVenueSeat>;
};

export type EventVenueUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type EventVenues = {
  __typename?: 'EventVenues';
  edges: Array<EventVenueEdge>;
  nodes: Array<EventVenue>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type EventWithUserReservation = EventInterface & {
  __typename?: 'EventWithUserReservation';
  configuration?: Maybe<EventConfiguration>;
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['ID']['output'];
  decryptionKey: Scalars['String']['output'];
  description: Scalars['String']['output'];
  endAt?: Maybe<Scalars['DateTime']['output']>;
  endRegistrationsAt?: Maybe<Scalars['DateTime']['output']>;
  eventVenueId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** The reservation of the current user for this event */
  myReservation?: Maybe<EventReservationWithTicketsAndQrCode>;
  organizationId: Scalars['ID']['output'];
  startAt?: Maybe<Scalars['DateTime']['output']>;
  startRegistrationsAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** The venue where this event is taking place */
  venue?: Maybe<EventVenue>;
  visibleAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Events = {
  __typename?: 'Events';
  edges: Array<EventEdge>;
  nodes: Array<Event>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type EventsQuery = {
  filterByPlayers?: InputMaybe<Array<EventsQueryPlayerIdWithTeamStatusInput>>;
  /** Order by type */
  orderBy?: EventsQueryOrderBy;
  /** Order direction */
  orderDirection?: OrderDirection;
  /** Show not visible events, only usable if the user has the correct permission */
  showNotVisible?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by event status */
  status?: EventsQueryStatus;
};

export enum EventsQueryOrderBy {
  EndAt = 'END_AT',
  RegistrationsEndAt = 'REGISTRATIONS_END_AT',
  RegistrationsStartAt = 'REGISTRATIONS_START_AT',
  StartAt = 'START_AT'
}

export type EventsQueryPlayerIdWithTeamStatusInput = {
  memberStatus?: InputMaybe<UserGroupMemberStatus>;
  /** Player id */
  playerId: Scalars['ID']['input'];
  reservationStatus?: InputMaybe<EventReservationStatus>;
};

export enum EventsQueryStatus {
  All = 'ALL',
  Ended = 'ENDED',
  RegistrationsClosed = 'REGISTRATIONS_CLOSED',
  RegistrationsEnded = 'REGISTRATIONS_ENDED',
  RegistrationsOpen = 'REGISTRATIONS_OPEN',
  Started = 'STARTED'
}

export type GameConfiguration = {
  __typename?: 'GameConfiguration';
  matchConfiguration: MatchConfiguration;
  teamsCount: Scalars['Float']['output'];
  useMatchScoresAsGameScore: Scalars['Boolean']['output'];
  wonMatchCountToWinGame: Scalars['Float']['output'];
};

export type GameConfigurationInput = {
  matchConfiguration: MatchConfigurationInput;
  teamsCount: Scalars['Float']['input'];
  useMatchScoresAsGameScore: Scalars['Boolean']['input'];
  wonMatchCountToWinGame: Scalars['Float']['input'];
};

export type GroupConfiguration = {
  __typename?: 'GroupConfiguration';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rounds: Array<RoundConfiguration>;
};

export type GroupConfigurationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rounds: Array<RoundConfigurationInput>;
};

export type GroupPermission = {
  __typename?: 'GroupPermission';
  id: Scalars['String']['output'];
  resources: Array<Scalars['String']['output']>;
};

export type GroupPermissionInput = {
  id: Scalars['String']['input'];
  resources: Array<Scalars['String']['input']>;
};

export enum GroupRepartitionMechanism {
  Balanced = 'BALANCED',
  SeedOptimized = 'SEED_OPTIMIZED'
}

export type Header = {
  __typename?: 'Header';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type HeaderInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum IdentityProviderAvailability {
  Preview = 'PREVIEW',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type IdentityProviderConfigurations = OAuthClientConfiguration | OpenidConfiguration;

export type IdentityProviderPropertyInput = {
  identityProviderId: Scalars['ID']['input'];
  property: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type IdentityProviderRequirements = {
  __typename?: 'IdentityProviderRequirements';
  requiredFields: Array<Scalars['String']['output']>;
};

export enum IdentityProviderType {
  Oauth2 = 'OAUTH2',
  Openid = 'OPENID'
}

export type KeyMapping = {
  __typename?: 'KeyMapping';
  mappedTo: Scalars['String']['output'];
  path: Scalars['String']['output'];
  private?: Maybe<Scalars['Boolean']['output']>;
};

export type KeyMappingInput = {
  mappedTo: Scalars['String']['input'];
  path: Scalars['String']['input'];
  private?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Leaderboard = {
  __typename?: 'Leaderboard';
  createdAt: Scalars['DateTime']['output'];
  /** The description of the leaderboard */
  description: Scalars['String']['output'];
  /** The ID of the leaderboard */
  id: Scalars['ID']['output'];
  /** The name of the leaderboard */
  name: Scalars['String']['output'];
  /** The ID of the organization this leaderboard belongs to */
  organizationId: Scalars['ID']['output'];
  /** The ID of the creator of this leaderboard */
  ownerId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LeaderboardEdge = {
  __typename?: 'LeaderboardEdge';
  cursor: Scalars['String']['output'];
  node: Array<Leaderboard>;
};

export type LeaderboardGetPlayerRankingInput = {
  leaderboardId: Scalars['ID']['input'];
  playerId: Scalars['ID']['input'];
  /** If not set, the current active season will be selected */
  seasonId?: InputMaybe<Scalars['ID']['input']>;
};

export type LeaderboardRegisterPlayerScoreInput = {
  /** The ID of the leaderboard to register the score to */
  leaderboardId: Scalars['ID']['input'];
  playerId: Scalars['ID']['input'];
  score: Scalars['PositiveInt']['input'];
};

export enum LeaderboardScoreCalculationType {
  Score = 'SCORE'
}

export type LeaderboardSeason = {
  __typename?: 'LeaderboardSeason';
  createdAt: Scalars['DateTime']['output'];
  end: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  leaderboardId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  start: Scalars['DateTime']['output'];
  startingScore: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LeaderboardSeasonBucket = {
  __typename?: 'LeaderboardSeasonBucket';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  minScore: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  scoreCalculationType: LeaderboardScoreCalculationType;
  seasonId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LeaderboardSeasonBucketEdge = {
  __typename?: 'LeaderboardSeasonBucketEdge';
  cursor: Scalars['String']['output'];
  node: Array<LeaderboardSeasonBucket>;
};

export type LeaderboardSeasonBucketScore = {
  __typename?: 'LeaderboardSeasonBucketScore';
  createdAt: Scalars['DateTime']['output'];
  lastRank?: Maybe<Scalars['Int']['output']>;
  lastScore?: Maybe<Scalars['Float']['output']>;
  leaderboardSeasonBucketId: Scalars['ID']['output'];
  playerProfileId: Scalars['ID']['output'];
  rank: Scalars['Float']['output'];
  score: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LeaderboardSeasonBucketScoreEdge = {
  __typename?: 'LeaderboardSeasonBucketScoreEdge';
  cursor: Scalars['String']['output'];
  node: Array<LeaderboardSeasonBucketScore>;
};

export type LeaderboardSeasonBucketScores = {
  __typename?: 'LeaderboardSeasonBucketScores';
  edges: Array<LeaderboardSeasonBucketScoreEdge>;
  nodes: Array<LeaderboardSeasonBucketScore>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type LeaderboardSeasonBuckets = {
  __typename?: 'LeaderboardSeasonBuckets';
  edges: Array<LeaderboardSeasonBucketEdge>;
  nodes: Array<LeaderboardSeasonBucket>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type LeaderboardSeasonEdge = {
  __typename?: 'LeaderboardSeasonEdge';
  cursor: Scalars['String']['output'];
  node: Array<LeaderboardSeason>;
};

export type LeaderboardSeasons = {
  __typename?: 'LeaderboardSeasons';
  edges: Array<LeaderboardSeasonEdge>;
  nodes: Array<LeaderboardSeason>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type Leaderboards = {
  __typename?: 'Leaderboards';
  edges: Array<LeaderboardEdge>;
  nodes: Array<Leaderboard>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type Limit = {
  __typename?: 'Limit';
  bucketDuration?: Maybe<BucketDuration>;
  default: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  max?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  scope: LimitScope;
  type: OrganizationLimitType;
};

export type LimitOverride = {
  __typename?: 'LimitOverride';
  limit: OrganizationLimitsType;
  value: Scalars['Float']['output'];
};

export enum LimitScope {
  Organization = 'ORGANIZATION',
  Resource = 'RESOURCE'
}

export type Limits = {
  __typename?: 'Limits';
  limits: Array<Limit>;
};

export type Log = {
  __typename?: 'Log';
  createdAt: Scalars['DateTime']['output'];
  data: LogData;
  id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  resourceId: Scalars['ID']['output'];
  type: LogType;
};

export enum LogAuthorType {
  App = 'APP',
  System = 'SYSTEM',
  User = 'USER'
}

export type LogData = {
  __typename?: 'LogData';
  author?: Maybe<Scalars['String']['output']>;
  authorType: LogAuthorType;
  newValue: Scalars['JSONObject']['output'];
  oldValue?: Maybe<Scalars['JSONObject']['output']>;
};

export type LogEdge = {
  __typename?: 'LogEdge';
  cursor: Scalars['String']['output'];
  node: Array<Log>;
};

export enum LogType {
  WebhookCall = 'WEBHOOK_CALL',
  WebhookCallError = 'WEBHOOK_CALL_ERROR'
}

export type Logs = {
  __typename?: 'Logs';
  edges: Array<LogEdge>;
  nodes: Array<Log>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type ManualSeedingGroupInput = {
  group: Scalars['ID']['input'];
  teams: Array<Scalars['ID']['input']>;
};

export type ManualSeedingInput = {
  groups: Array<ManualSeedingGroupInput>;
};

export type MappingConfiguration = {
  __typename?: 'MappingConfiguration';
  mappings: Array<KeyMapping>;
};

export type MappingConfigurationInput = {
  mappings: Array<KeyMappingInput>;
};

export type MatchConfiguration = {
  __typename?: 'MatchConfiguration';
  scoreFormula?: Maybe<Scalars['String']['output']>;
  variables: Array<MatchVariable>;
};

export type MatchConfigurationInput = {
  scoreFormula?: InputMaybe<Scalars['String']['input']>;
  variables: Array<MatchVariableInput>;
};

export enum MatchScoreStatus {
  Forfeit = 'FORFEIT',
  Loser = 'LOSER',
  Waiting = 'WAITING',
  Winner = 'WINNER'
}

export enum MatchStatus {
  Ended = 'ENDED',
  Started = 'STARTED',
  Waiting = 'WAITING'
}

export type MatchVariable = {
  __typename?: 'MatchVariable';
  defaultValue: Scalars['Float']['output'];
  displayIcon?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  formulaName: Scalars['String']['output'];
};

export type MatchVariableInput = {
  defaultValue: Scalars['Float']['input'];
  displayIcon?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  formulaName: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Generates access token for an account identity provider with the stored refresh token */
  accountIdentityGenerateAccessToken: Scalars['String']['output'];
  accountIdentityRefresh: AccountIdentity;
  answerToTournamentTeamInvite: Scalars['Boolean']['output'];
  attachOrUpdateIdentityToAccount: AccountIdentity;
  confirmTournamentAttendance: TournamentTeam;
  confirmTournamentTeam: TournamentTeam;
  createIdentityProvider: OrganizationIdentityProvider;
  createLeaderboard: Leaderboard;
  createLeaderboardSeason: LeaderboardSeason;
  createLeaderboardSeasonBucket: LeaderboardSeasonBucket;
  createOrUpdateMyPlayerProfile: Player;
  createOrganization: Organization;
  createOrganizationApp: OrganizationApp;
  createOrganizationGroup: OrganizationGroup;
  createPlayer: Player;
  /** Create a new tournament */
  createTournament: Tournament;
  createTournamentStep: TournamentStep;
  createWebhook: Webhook;
  deleteAccount: Scalars['Boolean']['output'];
  deleteAnyTournamentTeam: Scalars['Boolean']['output'];
  deleteConfiguration: Scalars['Boolean']['output'];
  deleteIdentityProvider: Scalars['Boolean']['output'];
  deleteLeaderboard: Scalars['Void']['output'];
  deleteLeaderboardSeason: Scalars['Void']['output'];
  deleteLeaderboardSeasonBucket: Scalars['Void']['output'];
  deleteOrganizationApp: Scalars['Boolean']['output'];
  deleteOrganizationAttachedApp: Scalars['Boolean']['output'];
  deleteOrganizationGroup: Scalars['Boolean']['output'];
  deleteOrganizationMembership: Scalars['Boolean']['output'];
  deletePlayer: Scalars['Boolean']['output'];
  /** Delete a tournament */
  deleteTournament: Scalars['Boolean']['output'];
  deleteTournamentStep: Scalars['Boolean']['output'];
  deleteTournamentTeam: Scalars['Boolean']['output'];
  deleteTournamentTeamInvite: Scalars['Boolean']['output'];
  deleteWebhook: Webhook;
  /** Create a new event */
  eventCreate: Event;
  /** Delete an event */
  eventDelete: Scalars['Boolean']['output'];
  eventReservationConfirmPresence: EventReservation;
  eventReservationStartRegistrationSession: EventReservation;
  eventReservationUpdateStatus: EventReservation;
  eventReservationValidateAndPay: EventReservationValidateAndPay;
  eventTicketConfigurationCreate: EventTicketConfiguration;
  eventTicketConfigurationDelete: EventTicketConfiguration;
  eventTicketConfigurationUpdate: EventTicketConfiguration;
  /** Update an event */
  eventUpdate: Event;
  eventVenueCreate: EventVenue;
  eventVenueDelete: EventVenue;
  eventVenueRequestImageUpdate: PresignedUrl;
  eventVenueSeatTypeCreate: EventVenueSeatType;
  eventVenueSeatTypeDelete: EventVenueSeatType;
  eventVenueSeatTypeUpdate: EventVenueSeatType;
  eventVenueSeatsUpdate: EventVenueSeatsUpdateResult;
  eventVenueUpdate: EventVenue;
  eventVenueValidateImage: EventVenue;
  generateTournamentStep: Scalars['Boolean']['output'];
  inviteTournamentTeamMember: Scalars['Boolean']['output'];
  leaderboardRegisterPlayerScore: LeaderboardSeasonBucketScore;
  organizationPaymentCollectionConfigurationCreateOrUpdateAccount: Scalars['String']['output'];
  organizationSkillRatingCreate: OrganizationSkillRating;
  organizationSkillRatingDelete: Scalars['Void']['output'];
  organizationSkillRatingUpdate: OrganizationSkillRating;
  organizationSkillRatingsRegisterMatch: Array<OrganizationSkillRatingsRegisterMatchPlayer>;
  organizationSubscriptionItemsUpdate: Scalars['Void']['output'];
  platformCreate: Platform;
  platformDelete: Scalars['Void']['output'];
  platformFileCreate: PlatformCdnFile;
  platformFileDelete: Scalars['Void']['output'];
  platformFileGenerateUploadUrl: PresignedUrl;
  platformFilePublish: PlatformCdnFile;
  platformFileUpdate: PlatformCdnFile;
  platformFileValidateFileUpload: PlatformCdnFile;
  platformForceCustomDomainRecheck: CustomDomainData;
  platformRegisterCustomDomain: CustomDomainData;
  platformRemoveCustomDomain: Scalars['Void']['output'];
  platformTemplateApply: Platform;
  platformTemplateCreate: PlatformTemplate;
  platformTemplateDelete: Scalars['Void']['output'];
  platformTemplateUpdate: PlatformTemplate;
  platformUpdate: Platform;
  regenerateWebhookSecret: Webhook;
  registerCustomTournamentTeams: Array<TournamentTeam>;
  registerTournamentTeam: TournamentTeam;
  resetOrganizationAppSecret: Scalars['String']['output'];
  seedTournamentStep: Scalars['Boolean']['output'];
  setOrganizationAttachedApp: OrganizationAttachedApp;
  setOrganizationMembership: OrganizationMember;
  startTournamentStep: Scalars['Boolean']['output'];
  stripeCreatePortalSession: Scalars['String']['output'];
  tournamentAdminDelete: Scalars['Boolean']['output'];
  tournamentAdminUpsert: TournamentAdmin;
  updateConfiguration: Configuration;
  updateIdentityProvider: OrganizationIdentityProvider;
  updateLeaderboard: Leaderboard;
  updateLeaderboardSeason: LeaderboardSeason;
  updateLeaderboardSeasonBucket: LeaderboardSeasonBucket;
  updateMatchScores: TournamentStepGroupRoundGameMatch;
  updateOrganization: Organization;
  updateOrganizationApp: OrganizationApp;
  updateOrganizationGroup: OrganizationGroup;
  updatePlayer: Player;
  /** Update a tournament */
  updateTournament: Tournament;
  updateTournamentStep: TournamentStep;
  updateTournamentTeam: TournamentTeam;
  updateTournamentTeamStatus: TournamentTeam;
  updateWebhook: Webhook;
};


export type MutationAccountIdentityGenerateAccessTokenArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  identityProviderId: Scalars['ID']['input'];
};


export type MutationAccountIdentityRefreshArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  identityProviderId: Scalars['ID']['input'];
};


export type MutationAnswerToTournamentTeamInviteArgs = {
  accept: Scalars['Boolean']['input'];
  teamId: Scalars['ID']['input'];
};


export type MutationAttachOrUpdateIdentityToAccountArgs = {
  identityProviderId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};


export type MutationConfirmTournamentAttendanceArgs = {
  attendance: Scalars['Boolean']['input'];
  tournamentId: Scalars['ID']['input'];
};


export type MutationConfirmTournamentTeamArgs = {
  confirm: Scalars['Boolean']['input'];
  teamId: Scalars['ID']['input'];
};


export type MutationCreateIdentityProviderArgs = {
  input: CreateOrganizationIdentityProvider;
};


export type MutationCreateLeaderboardArgs = {
  input: CreateLeaderboardInput;
};


export type MutationCreateLeaderboardSeasonArgs = {
  input: CreateLeaderboardSeasonInput;
  leaderboardId: Scalars['ID']['input'];
};


export type MutationCreateLeaderboardSeasonBucketArgs = {
  input: CreateLeaderboardSeasonBucketInput;
  seasonId: Scalars['ID']['input'];
};


export type MutationCreateOrUpdateMyPlayerProfileArgs = {
  input: UpdateMePlayerInput;
};


export type MutationCreateOrganizationArgs = {
  organization: CreateOrganizationInput;
};


export type MutationCreateOrganizationAppArgs = {
  input: CreateOrganizationAppInput;
};


export type MutationCreateOrganizationGroupArgs = {
  input: CreateOrganizationGroupInput;
};


export type MutationCreatePlayerArgs = {
  input: CreatePlayerInput;
};


export type MutationCreateTournamentArgs = {
  input: CreateTournamentInput;
};


export type MutationCreateTournamentStepArgs = {
  configuration: StepConfigurationInput;
  step: CreateTournamentStepInput;
  tournamentId: Scalars['ID']['input'];
};


export type MutationCreateWebhookArgs = {
  input: CreateWebhookInput;
};


export type MutationDeleteAccountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAnyTournamentTeamArgs = {
  teamId: Scalars['ID']['input'];
};


export type MutationDeleteConfigurationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteIdentityProviderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLeaderboardArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLeaderboardSeasonArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLeaderboardSeasonBucketArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationAppArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOrganizationAttachedAppArgs = {
  input: DeleteOrganizationAttachedAppInput;
};


export type MutationDeleteOrganizationGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationMembershipArgs = {
  input: DeleteOrganizationMembershipInput;
};


export type MutationDeletePlayerArgs = {
  customId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteTournamentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTournamentStepArgs = {
  stepId: Scalars['ID']['input'];
};


export type MutationDeleteTournamentTeamArgs = {
  tournamentId: Scalars['ID']['input'];
};


export type MutationDeleteTournamentTeamInviteArgs = {
  playerId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};


export type MutationDeleteWebhookArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEventCreateArgs = {
  input: EventCreateInput;
};


export type MutationEventDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEventReservationConfirmPresenceArgs = {
  confirmed: Scalars['Boolean']['input'];
  eventId: Scalars['ID']['input'];
};


export type MutationEventReservationStartRegistrationSessionArgs = {
  eventId: Scalars['ID']['input'];
  input: EventReservationRegisterTicketsInput;
};


export type MutationEventReservationUpdateStatusArgs = {
  confirmPaymentRefundOrCancel?: InputMaybe<Scalars['Boolean']['input']>;
  reservationId: Scalars['ID']['input'];
  status: EventReservationStatus;
};


export type MutationEventReservationValidateAndPayArgs = {
  eventId: Scalars['ID']['input'];
  options: EventReservationValidateAndPayInput;
};


export type MutationEventTicketConfigurationCreateArgs = {
  input: EventTicketConfigurationCreateInput;
};


export type MutationEventTicketConfigurationDeleteArgs = {
  ticketConfigurationId: Scalars['ID']['input'];
};


export type MutationEventTicketConfigurationUpdateArgs = {
  input: EventTicketConfigurationUpdateInput;
  ticketConfigurationId: Scalars['ID']['input'];
};


export type MutationEventUpdateArgs = {
  id: Scalars['ID']['input'];
  input: EventUpdateInput;
};


export type MutationEventVenueCreateArgs = {
  input: EventVenueCreateInput;
};


export type MutationEventVenueDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEventVenueRequestImageUpdateArgs = {
  id: Scalars['ID']['input'];
  size: Scalars['Float']['input'];
};


export type MutationEventVenueSeatTypeCreateArgs = {
  input: EventVenueSeatTypeCreateInput;
  venueId: Scalars['ID']['input'];
};


export type MutationEventVenueSeatTypeDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEventVenueSeatTypeUpdateArgs = {
  id: Scalars['ID']['input'];
  input: EventVenueSeatTypeUpdateInput;
};


export type MutationEventVenueSeatsUpdateArgs = {
  input: EventVenueSeatsUpdateInput;
  venueId: Scalars['ID']['input'];
};


export type MutationEventVenueUpdateArgs = {
  id: Scalars['ID']['input'];
  input: EventVenueUpdateInput;
};


export type MutationEventVenueValidateImageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGenerateTournamentStepArgs = {
  stepId: Scalars['ID']['input'];
  teamsCount: Scalars['Int']['input'];
};


export type MutationInviteTournamentTeamMemberArgs = {
  playerId: Scalars['ID']['input'];
  tournamentId: Scalars['ID']['input'];
};


export type MutationLeaderboardRegisterPlayerScoreArgs = {
  input: LeaderboardRegisterPlayerScoreInput;
};


export type MutationOrganizationSkillRatingCreateArgs = {
  input: OrganizationSkillRatingCreateInput;
};


export type MutationOrganizationSkillRatingDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationOrganizationSkillRatingUpdateArgs = {
  id: Scalars['ID']['input'];
  input: OrganizationSkillRatingUpdateInput;
};


export type MutationOrganizationSkillRatingsRegisterMatchArgs = {
  input: OrganizationSkillRatingsRegisterMatchInput;
  organizationSkillRatingId: Scalars['ID']['input'];
};


export type MutationOrganizationSubscriptionItemsUpdateArgs = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  items: Array<OrganizationSubscriptionItemUpdateInput>;
};


export type MutationPlatformCreateArgs = {
  input: CreatePlatformInput;
};


export type MutationPlatformDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPlatformFileCreateArgs = {
  input: PlatformCdnFileCreateInput;
  platformId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationPlatformFileDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPlatformFileGenerateUploadUrlArgs = {
  id: Scalars['ID']['input'];
  size: Scalars['Float']['input'];
};


export type MutationPlatformFilePublishArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPlatformFileUpdateArgs = {
  id: Scalars['ID']['input'];
  input: PlatformFileUpdateInput;
};


export type MutationPlatformFileValidateFileUploadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPlatformForceCustomDomainRecheckArgs = {
  hostname: Scalars['String']['input'];
};


export type MutationPlatformRegisterCustomDomainArgs = {
  hostname: Scalars['String']['input'];
  platformId: Scalars['ID']['input'];
};


export type MutationPlatformRemoveCustomDomainArgs = {
  hostname: Scalars['String']['input'];
};


export type MutationPlatformTemplateApplyArgs = {
  platformId: Scalars['ID']['input'];
  platformTemplateId: Scalars['ID']['input'];
};


export type MutationPlatformTemplateCreateArgs = {
  input: CreatePlatformTemplateInput;
};


export type MutationPlatformTemplateDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPlatformTemplateUpdateArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePlatformTemplateInput;
};


export type MutationPlatformUpdateArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePlatformInput;
};


export type MutationRegenerateWebhookSecretArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRegisterCustomTournamentTeamsArgs = {
  input: RegisterCustomTeamsInput;
  tournamentId: Scalars['ID']['input'];
};


export type MutationRegisterTournamentTeamArgs = {
  input: RegisterTournamentTeamInput;
  tournamentId: Scalars['ID']['input'];
};


export type MutationResetOrganizationAppSecretArgs = {
  id: Scalars['String']['input'];
};


export type MutationSeedTournamentStepArgs = {
  input: SeedingInput;
  stepId: Scalars['ID']['input'];
};


export type MutationSetOrganizationAttachedAppArgs = {
  input: SetOrganizationAttachedAppInput;
};


export type MutationSetOrganizationMembershipArgs = {
  input: SetOrganizationMembershipInput;
};


export type MutationStartTournamentStepArgs = {
  stepId: Scalars['ID']['input'];
};


export type MutationTournamentAdminDeleteArgs = {
  input: TournamentAdminDeleteInput;
};


export type MutationTournamentAdminUpsertArgs = {
  input: TournamentAdminUpsertInput;
};


export type MutationUpdateConfigurationArgs = {
  id: Scalars['ID']['input'];
  input: CreateConfigurationInput;
};


export type MutationUpdateIdentityProviderArgs = {
  input: UpdateOrganizationIdentityProvider;
  providerId: Scalars['ID']['input'];
};


export type MutationUpdateLeaderboardArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLeaderboardInput;
};


export type MutationUpdateLeaderboardSeasonArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLeaderboardSeasonInput;
};


export type MutationUpdateLeaderboardSeasonBucketArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLeaderboardSeasonBucketInput;
};


export type MutationUpdateMatchScoresArgs = {
  input: Array<UpdateMatchScoreInput>;
  matchId: Scalars['ID']['input'];
};


export type MutationUpdateOrganizationArgs = {
  organization: UpdateOrganizationInput;
};


export type MutationUpdateOrganizationAppArgs = {
  id: Scalars['String']['input'];
  input: UpdateOrganizationAppInput;
};


export type MutationUpdateOrganizationGroupArgs = {
  id: Scalars['ID']['input'];
  input: CreateOrganizationGroupInput;
};


export type MutationUpdatePlayerArgs = {
  customId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  input: UpdatePlayerInput;
};


export type MutationUpdateTournamentArgs = {
  id: Scalars['ID']['input'];
  input: UpdateTournamentInput;
};


export type MutationUpdateTournamentStepArgs = {
  configuration: StepConfigurationInput;
  step: CreateTournamentStepInput;
  stepId: Scalars['ID']['input'];
};


export type MutationUpdateTournamentTeamArgs = {
  input: UpdateTournamentTeamInput;
  teamId?: InputMaybe<Scalars['ID']['input']>;
  tournamentId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateTournamentTeamStatusArgs = {
  status: TournamentTeamStatus;
  tournamentTeamId: Scalars['ID']['input'];
};


export type MutationUpdateWebhookArgs = {
  id: Scalars['ID']['input'];
  input: UpdateWebhookInput;
};

export type NumberConditionInput = {
  conditionType: NumberConditionType;
  value: Scalars['Float']['input'];
};

export enum NumberConditionType {
  Bt = 'BT',
  Bte = 'BTE',
  Eq = 'EQ',
  Lt = 'LT',
  Lte = 'LTE',
  Neq = 'NEQ'
}

export type NumericCondition = {
  __typename?: 'NumericCondition';
  conditionType: NumberConditionType;
  value: Scalars['Float']['output'];
};

export type OAuthClientConfiguration = {
  __typename?: 'OAuthClientConfiguration';
  authorizationEndpoint?: Maybe<Scalars['String']['output']>;
  authorizationUrl?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  dataRetrievers: Array<DataRetrievalConfig>;
  issuer?: Maybe<Scalars['String']['output']>;
  providerType: IdentityProviderType;
  redirectUrl: Scalars['String']['output'];
  tokenEndpoint?: Maybe<Scalars['String']['output']>;
  tokenEndpointAuthMethod?: Maybe<OauthEndpointAuthMethod>;
};

export type OAuthClientConfigurationInput = {
  authorizationEndpoint?: InputMaybe<Scalars['String']['input']>;
  authorizationUrl?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  dataRetrievers: Array<DataRetrievalConfigInput>;
  issuer?: InputMaybe<Scalars['String']['input']>;
  providerType: IdentityProviderType;
  redirectUrl: Scalars['String']['input'];
  tokenEndpoint?: InputMaybe<Scalars['String']['input']>;
  tokenEndpointAuthMethod?: InputMaybe<OauthEndpointAuthMethod>;
};

export enum OauthEndpointAuthMethod {
  ClientSecretBasic = 'CLIENT_SECRET_BASIC',
  ClientSecretJwt = 'CLIENT_SECRET_JWT',
  ClientSecretPost = 'CLIENT_SECRET_POST',
  None = 'NONE',
  PrivateKeyJwt = 'PRIVATE_KEY_JWT',
  SelfSignedTlsClientAuth = 'SELF_SIGNED_TLS_CLIENT_AUTH',
  TlsClientAuth = 'TLS_CLIENT_AUTH'
}

export type OmitObjectType = {
  __typename?: 'OmitObjectType';
  createdAt: Scalars['DateTime']['output'];
  eventId: Scalars['ID']['output'];
  groupId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sessionValidUntil: Scalars['DateTime']['output'];
  status: EventReservationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type OmitObjectTypeEdge = {
  __typename?: 'OmitObjectTypeEdge';
  cursor: Scalars['String']['output'];
  node: Array<OmitObjectType>;
};

export type OpenidConfiguration = {
  __typename?: 'OpenidConfiguration';
  authorizationEndpoint?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  dataRetrievers: Array<DataRetrievalConfig>;
  issuer?: Maybe<Scalars['String']['output']>;
  providerType: IdentityProviderType;
  redirectUrl: Scalars['String']['output'];
};

export type OpenidConfigurationInput = {
  authorizationEndpoint?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  dataRetrievers: Array<DataRetrievalConfigInput>;
  issuer?: InputMaybe<Scalars['String']['input']>;
  providerType: IdentityProviderType;
  redirectUrl: Scalars['String']['input'];
};

export type OrderByProperty = {
  order?: InputMaybe<OrderDirection>;
  property: Scalars['String']['input'];
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Organization = {
  __typename?: 'Organization';
  configuration: OrganizationConfiguration;
  description: Scalars['String']['output'];
  groups: Array<OrganizationGroup>;
  id: Scalars['ID']['output'];
  identityProviders: Array<PublicOrganizationIdentityProvider>;
  members: Array<OrganizationMember>;
  name: Scalars['String']['output'];
  ownerId?: Maybe<Scalars['ID']['output']>;
  stripeConfigured?: Maybe<Scalars['Boolean']['output']>;
  tournaments: Array<Tournament>;
};


export type OrganizationIdentityProvidersArgs = {
  login: Scalars['Boolean']['input'];
};

export type OrganizationApp = {
  __typename?: 'OrganizationApp';
  configuration: OrganizationAppConfig;
  createdAt: Scalars['DateTime']['output'];
  creator: Account;
  creatorId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  public?: Maybe<Scalars['Boolean']['output']>;
  secret?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationAppConfig = {
  __typename?: 'OrganizationAppConfig';
  logoutRedirectUrls: Array<Scalars['String']['output']>;
  metadata: OrganizationAppConfigMetadata;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  redirectUrls: Array<Scalars['String']['output']>;
  scope: Scalars['String']['output'];
};

export type OrganizationAppConfigMetadata = {
  __typename?: 'OrganizationAppConfigMetadata';
  consentUrl: Scalars['String']['output'];
  creatorId: Scalars['String']['output'];
  loginUrl: Scalars['String']['output'];
  public: Scalars['Boolean']['output'];
  requiresConsent: Scalars['Boolean']['output'];
};

export type OrganizationAppEdge = {
  __typename?: 'OrganizationAppEdge';
  cursor: Scalars['String']['output'];
  node: Array<OrganizationApp>;
};

export type OrganizationApps = {
  __typename?: 'OrganizationApps';
  edges: Array<OrganizationAppEdge>;
  nodes: Array<OrganizationApp>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type OrganizationAttachedApp = {
  __typename?: 'OrganizationAttachedApp';
  app: OrganizationApp;
  appId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  group: OrganizationGroup;
  groupId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  permissions?: Maybe<Array<GroupPermission>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationAttachedAppEdge = {
  __typename?: 'OrganizationAttachedAppEdge';
  cursor: Scalars['String']['output'];
  node: Array<OrganizationAttachedApp>;
};

export type OrganizationAttachedApps = {
  __typename?: 'OrganizationAttachedApps';
  edges: Array<OrganizationAttachedAppEdge>;
  nodes: Array<OrganizationAttachedApp>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type OrganizationConfiguration = {
  __typename?: 'OrganizationConfiguration';
  customFields: Array<Property>;
};

export type OrganizationConfigurationInput = {
  customFields: Array<PropertyInput>;
};

export type OrganizationGroup = {
  __typename?: 'OrganizationGroup';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  permissions: Array<GroupPermission>;
  type: OrganizationGroupType;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationGroupEdge = {
  __typename?: 'OrganizationGroupEdge';
  cursor: Scalars['String']['output'];
  node: Array<OrganizationGroup>;
};

export enum OrganizationGroupType {
  Admin = 'ADMIN',
  Anonymous = 'ANONYMOUS',
  Connected = 'CONNECTED',
  Member = 'MEMBER'
}

export type OrganizationGroups = {
  __typename?: 'OrganizationGroups';
  edges: Array<OrganizationGroupEdge>;
  nodes: Array<OrganizationGroup>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type OrganizationIdentityProvider = {
  __typename?: 'OrganizationIdentityProvider';
  allowLogin: Scalars['Boolean']['output'];
  configuration?: Maybe<IdentityProviderConfigurations>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identityProviderId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  organizationId?: Maybe<Scalars['ID']['output']>;
  parentIdentityProvider?: Maybe<PublicIdentityProvider>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationLimit = {
  __typename?: 'OrganizationLimit';
  current: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  max: Scalars['Float']['output'];
};

export enum OrganizationLimitType {
  Boolean = 'BOOLEAN',
  Bytes = 'BYTES',
  Days = 'DAYS',
  Number = 'NUMBER'
}

export type OrganizationLimits = {
  __typename?: 'OrganizationLimits';
  limits: Array<OrganizationLimit>;
};

export enum OrganizationLimitsType {
  AnonymousPlayerProfiles = 'ANONYMOUS_PLAYER_PROFILES',
  Apps = 'APPS',
  AttachedApps = 'ATTACHED_APPS',
  CustomFields = 'CUSTOM_FIELDS',
  CustomIdentityProviders = 'CUSTOM_IDENTITY_PROVIDERS',
  EventsMaxGroupSize = 'EVENTS_MAX_GROUP_SIZE',
  EventsMaxRegistrationRules = 'EVENTS_MAX_REGISTRATION_RULES',
  EventsMaxTickets = 'EVENTS_MAX_TICKETS',
  EventsMaxTicketsTypes = 'EVENTS_MAX_TICKETS_TYPES',
  EventVenues = 'EVENT_VENUES',
  EventVenueMaxSeats = 'EVENT_VENUE_MAX_SEATS',
  EventVenueMaxSeatTypes = 'EVENT_VENUE_MAX_SEAT_TYPES',
  Leaderboards = 'LEADERBOARDS',
  LeaderboardBuckets = 'LEADERBOARD_BUCKETS',
  LeaderboardEntries = 'LEADERBOARD_ENTRIES',
  LeaderboardSeasons = 'LEADERBOARD_SEASONS',
  OrganizationGroups = 'ORGANIZATION_GROUPS',
  OrganizationMembers = 'ORGANIZATION_MEMBERS',
  OrganizationStorageSize = 'ORGANIZATION_STORAGE_SIZE',
  Platforms = 'PLATFORMS',
  PlatformCustomDomains = 'PLATFORM_CUSTOM_DOMAINS',
  PlatformTemplates = 'PLATFORM_TEMPLATES',
  RegisteredUsers = 'REGISTERED_USERS',
  SkillRatings = 'SKILL_RATINGS',
  TournamentRegistrationRules = 'TOURNAMENT_REGISTRATION_RULES',
  TournamentSteps = 'TOURNAMENT_STEPS',
  TournamentStepGroups = 'TOURNAMENT_STEP_GROUPS',
  TournamentTeamsRegistered = 'TOURNAMENT_TEAMS_REGISTERED',
  TournamentTeamSize = 'TOURNAMENT_TEAM_SIZE',
  Webhooks = 'WEBHOOKS',
  WhiteLabel = 'WHITE_LABEL'
}

export type OrganizationMember = {
  __typename?: 'OrganizationMember';
  account: Account;
  createdAt: Scalars['DateTime']['output'];
  group: OrganizationGroup;
  groupId: Scalars['ID']['output'];
  memberId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  permissions?: Maybe<Array<GroupPermission>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationMemberEdge = {
  __typename?: 'OrganizationMemberEdge';
  cursor: Scalars['String']['output'];
  node: Array<OrganizationMember>;
};

export type OrganizationMembers = {
  __typename?: 'OrganizationMembers';
  edges: Array<OrganizationMemberEdge>;
  nodes: Array<OrganizationMember>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type OrganizationPaymentCollectionConfiguration = {
  __typename?: 'OrganizationPaymentCollectionConfiguration';
  wellPlayedFeePercent: Scalars['Float']['output'];
  wellPlayedMinimalFee: Scalars['Float']['output'];
};

export type OrganizationSkillRating = {
  __typename?: 'OrganizationSkillRating';
  configuration: SkillRatingConfiguration;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  type: SkillRatingType;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationSkillRatingCreateInput = {
  configuration: SkillRatingConfigurationInput;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: SkillRatingType;
};

export type OrganizationSkillRatingUpdateInput = {
  configuration?: InputMaybe<SkillRatingConfigurationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<SkillRatingType>;
};

export type OrganizationSkillRatingsRegisterMatchInput = {
  teams: Array<OrganizationSkillRatingsRegisterMatchTeamInput>;
};

export type OrganizationSkillRatingsRegisterMatchPlayer = {
  __typename?: 'OrganizationSkillRatingsRegisterMatchPlayer';
  id: Scalars['ID']['output'];
  leaderboardPointsUpdate?: Maybe<Scalars['Float']['output']>;
  newValue: Scalars['Float']['output'];
  oldValue: Scalars['Float']['output'];
};

export type OrganizationSkillRatingsRegisterMatchTeamInput = {
  players: Array<Scalars['ID']['input']>;
};

export type OrganizationSubscriptionItem = {
  __typename?: 'OrganizationSubscriptionItem';
  count: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  endAt: Scalars['DateTime']['output'];
  organizationId: Scalars['ID']['output'];
  subscriptionItem: SubscriptionItem;
  subscriptionItemId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationSubscriptionItemUpdateInput = {
  count: Scalars['Float']['input'];
  subscriptionItemId: Scalars['ID']['input'];
};

export type PageInfo = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
};

export type Permission = {
  __typename?: 'Permission';
  availableVariables: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  resources: Array<Scalars['String']['output']>;
};

export type Permissions = {
  __typename?: 'Permissions';
  organizationPermissions: Array<Permission>;
};

export type Platform = {
  __typename?: 'Platform';
  configuration?: Maybe<PlatformConfiguration>;
  createdAt: Scalars['DateTime']['output'];
  customDomains: Array<PlatformCustomDomainData>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PlatformCdnFile = {
  __typename?: 'PlatformCdnFile';
  config?: Maybe<PlatformCdnFileConfigs>;
  contents?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  devCdnPath?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  platformId?: Maybe<Scalars['ID']['output']>;
  platformTemplateId?: Maybe<Scalars['ID']['output']>;
  prodCdnPath?: Maybe<Scalars['String']['output']>;
  size: Scalars['Float']['output'];
  type: PlatformCdnFileType;
  updatedAt: Scalars['DateTime']['output'];
};

export type PlatformCdnFileConfigs = {
  __typename?: 'PlatformCdnFileConfigs';
  pageConfig?: Maybe<PlatformCdnFilePageConfig>;
};

export type PlatformCdnFileConfigsInput = {
  pageConfig?: InputMaybe<PlatformCdnFilePageConfigInput>;
};

export type PlatformCdnFileCreateInput = {
  config?: InputMaybe<PlatformCdnFileConfigsInput>;
  name: Scalars['String']['input'];
  type: PlatformCdnFileType;
};

export type PlatformCdnFilePageConfig = {
  __typename?: 'PlatformCdnFilePageConfig';
  path: Scalars['String']['output'];
};

export type PlatformCdnFilePageConfigInput = {
  path: Scalars['String']['input'];
};

export enum PlatformCdnFileType {
  Component = 'COMPONENT',
  Image = 'IMAGE',
  Page = 'PAGE',
  Text = 'TEXT'
}

export type PlatformConfiguration = {
  __typename?: 'PlatformConfiguration';
  whitelabel?: Maybe<Scalars['Boolean']['output']>;
};

export type PlatformConfigurationInput = {
  whitelabel?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PlatformCustomDomainData = {
  __typename?: 'PlatformCustomDomainData';
  configuration?: Maybe<CustomDomainData>;
  domain: Scalars['String']['output'];
};

export type PlatformFileUpdateInput = {
  config?: InputMaybe<PlatformCdnFileConfigsInput>;
};

export type PlatformTemplate = {
  __typename?: 'PlatformTemplate';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  ownerId: Scalars['ID']['output'];
  public: Scalars['Boolean']['output'];
  screenshotUrl: Scalars['URL']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Player = {
  __typename?: 'Player';
  createdAt: Scalars['DateTime']['output'];
  customFields: Array<PropertyConfigWithValue>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identities?: Maybe<Array<AccountIdentity>>;
  organizationCustomId?: Maybe<Scalars['ID']['output']>;
  owner?: Maybe<Account>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  profilePicUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};


export type PlayerCustomFieldsArgs = {
  properties?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PlayerCondition = {
  __typename?: 'PlayerCondition';
  condition: Condition;
  errorMessage?: Maybe<Scalars['String']['output']>;
  propertySource: PlayerConditionDataSource;
  propertySourceId?: Maybe<Scalars['ID']['output']>;
  ruleDescription?: Maybe<Scalars['String']['output']>;
};

export enum PlayerConditionDataSource {
  IdentityProvider = 'IDENTITY_PROVIDER',
  Player = 'PLAYER'
}

export type PlayerConditionInput = {
  condition: ConditionInput;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  propertySource: PlayerConditionDataSource;
  propertySourceId?: InputMaybe<Scalars['ID']['input']>;
  ruleDescription?: InputMaybe<Scalars['String']['input']>;
};

export type PlayerCustomFieldInput = {
  property: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type PlayerEdge = {
  __typename?: 'PlayerEdge';
  cursor: Scalars['String']['output'];
  node: Array<Player>;
};

export type PlayerSkillRating = {
  __typename?: 'PlayerSkillRating';
  createdAt: Scalars['DateTime']['output'];
  player: Player;
  playerProfileId: Scalars['ID']['output'];
  ratingId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type Players = {
  __typename?: 'Players';
  edges: Array<PlayerEdge>;
  nodes: Array<Player>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type Position = {
  __typename?: 'Position';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type PositionInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type PresenceAnalytics = {
  __typename?: 'PresenceAnalytics';
  anonymousSessions: Scalars['Int']['output'];
  authenticatedSessions: Scalars['Int']['output'];
  totalSessions: Scalars['Int']['output'];
};

export type PresignedUrl = {
  __typename?: 'PresignedUrl';
  fields: Array<PresignedUrlField>;
  url: Scalars['URL']['output'];
};

export type PresignedUrlField = {
  __typename?: 'PresignedUrlField';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Property = {
  __typename?: 'Property';
  editability?: Maybe<ProperyEditability>;
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  property: Scalars['String']['output'];
  /** @deprecated use visibility instead */
  public?: Maybe<Scalars['Boolean']['output']>;
  required: Scalars['Boolean']['output'];
  type: PropertyType;
  unique: Scalars['Boolean']['output'];
  visibility?: Maybe<PropertyVisibility>;
};

export enum PropertyCondition {
  DontExist = 'DONT_EXIST',
  Exists = 'EXISTS'
}

export type PropertyConfigWithValue = {
  __typename?: 'PropertyConfigWithValue';
  editability?: Maybe<ProperyEditability>;
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  property: Scalars['String']['output'];
  /** @deprecated use visibility instead */
  public?: Maybe<Scalars['Boolean']['output']>;
  required: Scalars['Boolean']['output'];
  type: PropertyType;
  unique: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
  visibility?: Maybe<PropertyVisibility>;
};

export type PropertyInput = {
  editability?: InputMaybe<ProperyEditability>;
  name: Scalars['String']['input'];
  order: Scalars['Float']['input'];
  property: Scalars['String']['input'];
  public?: InputMaybe<Scalars['Boolean']['input']>;
  required: Scalars['Boolean']['input'];
  type: PropertyType;
  unique: Scalars['Boolean']['input'];
  visibility?: InputMaybe<PropertyVisibility>;
};

export enum PropertyType {
  Boolean = 'BOOLEAN',
  Country = 'COUNTRY',
  Date = 'DATE',
  Phone = 'PHONE',
  String = 'STRING'
}

export type PropertyValueInput = {
  property: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum PropertyVisibility {
  Owner = 'OWNER',
  OwnerOrPermission = 'OWNER_OR_PERMISSION',
  Public = 'PUBLIC',
  WithPermission = 'WITH_PERMISSION'
}

export enum ProperyEditability {
  Always = 'ALWAYS',
  OneTime = 'ONE_TIME',
  WithPermission = 'WITH_PERMISSION'
}

export type PublicIdentityProvider = {
  __typename?: 'PublicIdentityProvider';
  /** The availability of the identity provider. */
  availability: IdentityProviderAvailability;
  createdAt: Scalars['DateTime']['output'];
  /** The description of the identity provider. */
  description?: Maybe<Scalars['String']['output']>;
  /** The icon URL of the identity provider. */
  icon?: Maybe<Scalars['String']['output']>;
  /** The ID of the identity provider. */
  id: Scalars['ID']['output'];
  /** The name of the identity provider. */
  name: Scalars['String']['output'];
  /** Identity provider create and update requirements */
  requirements: IdentityProviderRequirements;
  updatedAt: Scalars['DateTime']['output'];
};

export type PublicOrganizationIdentityProvider = {
  __typename?: 'PublicOrganizationIdentityProvider';
  allowLogin: Scalars['Boolean']['output'];
  authorizationUrl: Scalars['String']['output'];
  description: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  availablePermissions: Permissions;
  availableRootIdentityProvider: PublicIdentityProvider;
  configuration: Configuration;
  currentLeaderboardSeason: LeaderboardSeason;
  event: EventWithUserReservation;
  eventReservation: EventReservation;
  eventReservations: EventReservations;
  eventSeats: EventSeats;
  eventTicketConfiguration: EventTicketConfiguration;
  eventTicketConfigurations: Array<EventTicketConfiguration>;
  eventVenue: EventVenue;
  eventVenueSeatTypes: EventVenueSeatTypes;
  eventVenueSeats: EventVenueSeats;
  eventVenues: EventVenues;
  events: Events;
  getAvailableRootIdentityProviders: Array<PublicIdentityProvider>;
  getMyAccount: Account;
  identityProvider: OrganizationIdentityProvider;
  identityProviders: Array<OrganizationIdentityProvider>;
  leaderboard: Leaderboard;
  leaderboardCurrentPlayerRanking: LeaderboardSeasonBucketScore;
  leaderboardSeason: LeaderboardSeason;
  leaderboardSeasonBucket: LeaderboardSeasonBucket;
  leaderboardSeasonBucketScores: LeaderboardSeasonBucketScores;
  leaderboardSeasonBuckets: LeaderboardSeasonBuckets;
  leaderboardSeasons: LeaderboardSeasons;
  leaderboards: Leaderboards;
  limitDefinitions: Limits;
  logs: Logs;
  match: TournamentStepGroupRoundGameMatch;
  organization: Organization;
  organizationApp: OrganizationApp;
  organizationApps: OrganizationApps;
  organizationAttachedApps: OrganizationAttachedApps;
  organizationCurrentSubscriptionItems: Array<OrganizationSubscriptionItem>;
  organizationGroup: OrganizationGroup;
  organizationGroups: OrganizationGroups;
  organizationLimits: OrganizationLimits;
  organizationMembers: OrganizationMembers;
  organizationPaymentCollectionConfiguration?: Maybe<OrganizationPaymentCollectionConfiguration>;
  organizationSkillRating: OrganizationSkillRating;
  organizationSkillRatings: Array<OrganizationSkillRating>;
  platform?: Maybe<Platform>;
  platformCheckDomainAvailability: Scalars['Boolean']['output'];
  platformCustomDomains: Array<PlatformCustomDomainData>;
  platformFile: PlatformCdnFile;
  platformFiles: Array<PlatformCdnFile>;
  platformFindFromDomain: Platform;
  platformTemplate?: Maybe<PlatformTemplate>;
  platformTemplates: Array<PlatformTemplate>;
  platformTemplatesPublic: Array<PlatformTemplate>;
  platforms: Array<Platform>;
  player: Player;
  playerMatchesHistory: TournamentStepGroupRoundGameMatches;
  playerSkillRatings: Array<PlayerSkillRating>;
  players: Players;
  presenceAnalytics: PresenceAnalytics;
  searchPlayer: Array<Player>;
  subscriptionItems: Array<SubscriptionItem>;
  tournament: Tournament;
  tournamentAdmins: Array<TournamentAdmin>;
  tournamentPlayers: TeamMembers;
  tournamentStep: TournamentStep;
  tournamentStepGeneratedShape: Array<TournamentStepGroup>;
  tournamentStepGroupRoundGameMatchScoresGetForStep: TournamentStepGroupRoundGameMatchScores;
  tournamentSteps: Array<TournamentStep>;
  tournamentTeam: TournamentTeam;
  tournamentTeamInvites: TeamMembers;
  tournamentTeams: TournamentTeams;
  tournaments: Tournaments;
  userGroups: UserGroups;
  userOrganizations: Array<Organization>;
  webhook: Webhook;
  webhookAvailableEvents: Array<WebhookEventConfiguration>;
  webhooks: Array<Webhook>;
};


export type QueryAvailableRootIdentityProviderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConfigurationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCurrentLeaderboardSeasonArgs = {
  leaderboardId: Scalars['ID']['input'];
};


export type QueryEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEventReservationArgs = {
  reservationId: Scalars['ID']['input'];
};


export type QueryEventReservationsArgs = {
  eventId: Scalars['ID']['input'];
  page: PageInfo;
  query: EventReservationsQueryInput;
};


export type QueryEventSeatsArgs = {
  eventId: Scalars['ID']['input'];
  eventVenueId?: InputMaybe<Scalars['ID']['input']>;
  page: PageInfo;
};


export type QueryEventTicketConfigurationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEventTicketConfigurationsArgs = {
  eventId: Scalars['ID']['input'];
};


export type QueryEventVenueArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEventVenueSeatTypesArgs = {
  page: PageInfo;
  venueId: Scalars['ID']['input'];
};


export type QueryEventVenueSeatsArgs = {
  page: PageInfo;
  seatTypeId?: InputMaybe<Scalars['ID']['input']>;
  venueId: Scalars['ID']['input'];
};


export type QueryEventVenuesArgs = {
  page: PageInfo;
};


export type QueryEventsArgs = {
  page: PageInfo;
  query: EventsQuery;
};


export type QueryIdentityProviderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLeaderboardArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLeaderboardCurrentPlayerRankingArgs = {
  input: LeaderboardGetPlayerRankingInput;
};


export type QueryLeaderboardSeasonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLeaderboardSeasonBucketArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLeaderboardSeasonBucketScoresArgs = {
  leaderboardSeasonBucketId: Scalars['ID']['input'];
  page: PageInfo;
};


export type QueryLeaderboardSeasonBucketsArgs = {
  page: PageInfo;
  seasonId: Scalars['ID']['input'];
};


export type QueryLeaderboardSeasonsArgs = {
  leaderboardId: Scalars['ID']['input'];
  page: PageInfo;
};


export type QueryLeaderboardsArgs = {
  page: PageInfo;
};


export type QueryLimitDefinitionsArgs = {
  limits?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryLogsArgs = {
  page?: InputMaybe<PageInfo>;
  resourceId?: InputMaybe<Scalars['ID']['input']>;
  types?: InputMaybe<Array<LogType>>;
};


export type QueryMatchArgs = {
  matchId: Scalars['ID']['input'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganizationAppArgs = {
  id: Scalars['String']['input'];
};


export type QueryOrganizationAppsArgs = {
  page: PageInfo;
};


export type QueryOrganizationAttachedAppsArgs = {
  page: PageInfo;
};


export type QueryOrganizationGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganizationGroupsArgs = {
  page: PageInfo;
};


export type QueryOrganizationLimitsArgs = {
  limits?: InputMaybe<Array<OrganizationLimitsType>>;
};


export type QueryOrganizationMembersArgs = {
  page: PageInfo;
};


export type QueryOrganizationSkillRatingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPlatformArgs = {
  platformId: Scalars['ID']['input'];
};


export type QueryPlatformCheckDomainAvailabilityArgs = {
  domain: Scalars['URL']['input'];
};


export type QueryPlatformCustomDomainsArgs = {
  platformId: Scalars['ID']['input'];
};


export type QueryPlatformFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPlatformFilesArgs = {
  platformId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<PlatformCdnFileType>;
};


export type QueryPlatformFindFromDomainArgs = {
  domain: Scalars['String']['input'];
};


export type QueryPlatformTemplateArgs = {
  platformTemplateId: Scalars['ID']['input'];
};


export type QueryPlayerArgs = {
  customId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPlayerMatchesHistoryArgs = {
  page: PageInfo;
  playerId: Scalars['ID']['input'];
};


export type QueryPlayerSkillRatingsArgs = {
  playerId: Scalars['ID']['input'];
  skillRatingIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryPlayersArgs = {
  identityProviderProperties?: InputMaybe<Array<Scalars['String']['input']>>;
  identityProviders?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  page: PageInfo;
  query?: InputMaybe<QueryPlayersInput>;
};


export type QuerySearchPlayerArgs = {
  username: Scalars['String']['input'];
};


export type QueryTournamentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTournamentAdminsArgs = {
  tournamentId: Scalars['ID']['input'];
};


export type QueryTournamentPlayersArgs = {
  orderByProperty?: InputMaybe<OrderByProperty>;
  page: PageInfo;
  playerPropertiesFilter?: InputMaybe<Array<PropertyValueInput>>;
  playerUsernameQuery?: InputMaybe<Scalars['String']['input']>;
  status: TournamentTeamStatus;
  tournamentId: Scalars['ID']['input'];
};


export type QueryTournamentStepArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTournamentStepGeneratedShapeArgs = {
  stepId: Scalars['ID']['input'];
};


export type QueryTournamentStepGroupRoundGameMatchScoresGetForStepArgs = {
  page: PageInfo;
  stepId: Scalars['ID']['input'];
};


export type QueryTournamentStepsArgs = {
  tournamentId: Scalars['ID']['input'];
};


export type QueryTournamentTeamArgs = {
  memberStatus?: InputMaybe<TournamentTeamMemberStatus>;
  teamId: Scalars['ID']['input'];
};


export type QueryTournamentTeamInvitesArgs = {
  page: PageInfo;
  status?: InputMaybe<TournamentTeamMemberStatus>;
  tournamentId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryTournamentTeamsArgs = {
  memberStatus?: InputMaybe<TournamentTeamMemberStatus>;
  page: PageInfo;
  playerIdentityProviderPropertiesFilter?: InputMaybe<Array<IdentityProviderPropertyInput>>;
  playerPropertiesFilter?: InputMaybe<Array<PropertyValueInput>>;
  playerUsernameQuery?: InputMaybe<Scalars['String']['input']>;
  propertiesFilter?: InputMaybe<Array<PropertyValueInput>>;
  status?: InputMaybe<TournamentTeamStatus>;
  tournamentId: Scalars['ID']['input'];
};


export type QueryTournamentsArgs = {
  page: PageInfo;
  query: TournamentsQuery;
};


export type QueryUserGroupsArgs = {
  page: PageInfo;
  query: UserGroupsQueryInput;
};


export type QueryWebhookArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPlayersInput = {
  anonymous?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RegisterCustomTeamInput = {
  customFields?: InputMaybe<Array<PropertyValueInput>>;
  fromExistingTeamId?: InputMaybe<Scalars['ID']['input']>;
  members?: InputMaybe<Array<CustomTeamMemberInput>>;
  name: Scalars['String']['input'];
  status: TournamentTeamStatus;
  tag: Scalars['String']['input'];
};

export type RegisterCustomTeamsInput = {
  customTeams: Array<RegisterCustomTeamInput>;
};

export type RegisterTournamentTeamInput = {
  customFields?: InputMaybe<Array<PropertyValueInput>>;
  fromExistingTeamId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  tag: Scalars['String']['input'];
};

export type RegistrationConditions = {
  __typename?: 'RegistrationConditions';
  memberConditions: Array<PlayerCondition>;
  teamConditions: Array<TeamCondition>;
};

export type RegistrationConditionsInput = {
  memberConditions: Array<PlayerConditionInput>;
  teamConditions: Array<TeamConditionInput>;
};

export type ResponsePageInfo = {
  __typename?: 'ResponsePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type RoundConfiguration = {
  __typename?: 'RoundConfiguration';
  game: GameConfiguration;
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
};

export type RoundConfigurationInput = {
  game: GameConfigurationInput;
  name: Scalars['String']['input'];
  order: Scalars['Float']['input'];
};

/** automaticSeeding or manualSeeding must be set */
export type SeedingInput = {
  automaticSeeding?: InputMaybe<AutomaticSeedingInput>;
  manualSeeding?: InputMaybe<ManualSeedingInput>;
};

export enum SeedingMechanism {
  HalfShift = 'HALF_SHIFT',
  None = 'NONE',
  PairFlip = 'PAIR_FLIP',
  Reverse = 'REVERSE',
  ReverseHalfShift = 'REVERSE_HALF_SHIFT'
}

export type SetOrganizationAttachedAppInput = {
  appId: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['ID']['input']>;
  permissions?: InputMaybe<Array<GroupPermissionInput>>;
};

export type SetOrganizationMembershipInput = {
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  groupId?: InputMaybe<Scalars['ID']['input']>;
  permissions?: InputMaybe<Array<GroupPermissionInput>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type SkillRatingConfiguration = {
  __typename?: 'SkillRatingConfiguration';
  updateLeaderboardId?: Maybe<Scalars['ID']['output']>;
};

export type SkillRatingConfigurationInput = {
  updateLeaderboardId?: InputMaybe<Scalars['ID']['input']>;
};

export enum SkillRatingType {
  BradleyTerryPart = 'BRADLEY_TERRY_PART',
  BredleyTerryFull = 'BREDLEY_TERRY_FULL',
  PlackettLuce = 'PLACKETT_LUCE',
  ThurstoneMostellerFull = 'THURSTONE_MOSTELLER_FULL',
  ThurstoneMostellerPart = 'THURSTONE_MOSTELLER_PART'
}

export type StepConfiguration = {
  __typename?: 'StepConfiguration';
  groups: Array<GroupConfiguration>;
  type: ConfigurationType;
};

export type StepConfigurationInput = {
  groups: Array<GroupConfigurationInput>;
  type?: ConfigurationType;
};

export enum StepStatus {
  Configured = 'CONFIGURED',
  Ended = 'ENDED',
  Generated = 'GENERATED',
  Generating = 'GENERATING',
  Seeded = 'SEEDED',
  Seeding = 'SEEDING',
  Started = 'STARTED'
}

export enum StepType {
  Custom = 'CUSTOM',
  DoubleElim = 'DOUBLE_ELIM',
  RoundRobin = 'ROUND_ROBIN',
  Score = 'SCORE',
  SingleElim = 'SINGLE_ELIM'
}

export type StringCondition = {
  __typename?: 'StringCondition';
  conditionType: StringConditionType;
  value: Scalars['String']['output'];
};

export type StringConditionInput = {
  conditionType: StringConditionType;
  value: Scalars['String']['input'];
};

export enum StringConditionType {
  Eq = 'EQ',
  Neq = 'NEQ'
}

export enum StripeUiMode {
  Embedded = 'EMBEDDED',
  Hosted = 'HOSTED'
}

export type Subscription = {
  __typename?: 'Subscription';
  presenceUserConnected: UsersConnectedEvent;
  presenceUserDisconnected: UsersDisconnectedEvent;
};

export type SubscriptionItem = {
  __typename?: 'SubscriptionItem';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  limitsOverride: Array<LimitOverride>;
  maxCount?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type TeamCondition = {
  __typename?: 'TeamCondition';
  errorMessage?: Maybe<Scalars['String']['output']>;
  numericCondition?: Maybe<TeamNumericCondition>;
  property: Scalars['String']['output'];
  propertyCondition: PropertyCondition;
  stringCondition?: Maybe<StringCondition>;
};

export type TeamConditionInput = {
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  numericCondition?: InputMaybe<TeamNumericConditionInput>;
  property: Scalars['String']['input'];
  propertyCondition: PropertyCondition;
  stringCondition?: InputMaybe<StringConditionInput>;
};

export type TeamMembers = {
  __typename?: 'TeamMembers';
  edges: Array<TournamentTeamMemberEdge>;
  nodes: Array<TournamentTeamMemberDto>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type TeamNumericCondition = {
  __typename?: 'TeamNumericCondition';
  aggregationType?: Maybe<AggregationType>;
  conditionType: NumberConditionType;
  propertySource?: Maybe<PlayerConditionDataSource>;
  propertySourceId?: Maybe<Scalars['ID']['output']>;
  value: Scalars['Float']['output'];
};

export type TeamNumericConditionInput = {
  aggregationType?: InputMaybe<AggregationType>;
  conditionType: NumberConditionType;
  propertySource?: InputMaybe<PlayerConditionDataSource>;
  propertySourceId?: InputMaybe<Scalars['ID']['input']>;
  value: Scalars['Float']['input'];
};

export enum TeamStatusAfterRegistration {
  AwaitingForPayment = 'AWAITING_FOR_PAYMENT',
  AwaitingForPresenceConfirmation = 'AWAITING_FOR_PRESENCE_CONFIRMATION',
  Registered = 'REGISTERED'
}

export type Tournament = {
  __typename?: 'Tournament';
  configuration?: Maybe<TournamentConfiguration>;
  createdAt: Scalars['DateTime']['output'];
  /** Account that created this tournament */
  createdBy: Account;
  createdById: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  endAt?: Maybe<Scalars['DateTime']['output']>;
  endRegistrationsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Current authenticated team registered to this event */
  myTeam?: Maybe<TournamentTeam>;
  organizationId: Scalars['ID']['output'];
  startAt?: Maybe<Scalars['DateTime']['output']>;
  startRegistrationsAt?: Maybe<Scalars['DateTime']['output']>;
  /** Steps registered for this tournament */
  steps: Array<TournamentStep>;
  teamScores: Array<TournamentTeamScore>;
  /** Teams registered to this tournament */
  teams: TournamentTeams;
  title: Scalars['String']['output'];
  tournamentSeriesId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  visibleAt?: Maybe<Scalars['DateTime']['output']>;
};


export type TournamentTeamsArgs = {
  page: PageInfo;
  status?: InputMaybe<TournamentTeamStatus>;
};

export type TournamentAdmin = {
  __typename?: 'TournamentAdmin';
  account: Account;
  accountId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  permissions: Array<TournamentAdminPermissions>;
  tournamentId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TournamentAdminDeleteInput = {
  accountId: Scalars['ID']['input'];
  tournamentId: Scalars['ID']['input'];
};

export enum TournamentAdminPermissions {
  ManagePermissions = 'MANAGE_PERMISSIONS',
  ManageScores = 'MANAGE_SCORES',
  ManageTeams = 'MANAGE_TEAMS',
  ManageTournament = 'MANAGE_TOURNAMENT'
}

export type TournamentAdminUpsertInput = {
  accountId: Scalars['ID']['input'];
  permissions: Array<TournamentAdminPermissions>;
  tournamentId: Scalars['ID']['input'];
};

export type TournamentConfiguration = {
  __typename?: 'TournamentConfiguration';
  /** Custom fields that are defined on each teams */
  customFields?: Maybe<Array<Property>>;
  /** Condition rules to register to the tournament */
  registrationConditions?: Maybe<RegistrationConditions>;
  /** Maximum team size to register to the tournament */
  teamMaxSize?: Maybe<Scalars['Float']['output']>;
  /** Minimum team size to register to the tournament */
  teamMinSize?: Maybe<Scalars['Float']['output']>;
  /** Status of the team after registration, REGISTERED is the default state, AWAITING_FOR_PRESENCE_CONFIRMATION means that the team has been validated and is waiting for presence confirmation, AWAITING_FOR_PAYMENT means that the team has to pay to be validated */
  teamStatusAfterRegistration?: Maybe<TeamStatusAfterRegistration>;
  /** Maximum number of registered teams */
  teamsCount?: Maybe<Scalars['Float']['output']>;
  type: ConfigurationType;
};

export type TournamentConfigurationInput = {
  /** Custom fields that are defined on each teams */
  customFields?: InputMaybe<Array<PropertyInput>>;
  /** Condition rules to register to the tournament */
  registrationConditions?: InputMaybe<RegistrationConditionsInput>;
  /** Maximum team size to register to the tournament */
  teamMaxSize?: InputMaybe<Scalars['Float']['input']>;
  /** Minimum team size to register to the tournament */
  teamMinSize?: InputMaybe<Scalars['Float']['input']>;
  /** Status of the team after registration, REGISTERED is the default state, AWAITING_FOR_PRESENCE_CONFIRMATION means that the team has been validated and is waiting for presence confirmation, AWAITING_FOR_PAYMENT means that the team has to pay to be validated */
  teamStatusAfterRegistration?: InputMaybe<TeamStatusAfterRegistration>;
  /** Maximum number of registered teams */
  teamsCount?: InputMaybe<Scalars['Float']['input']>;
  type?: ConfigurationType;
};

export type TournamentEdge = {
  __typename?: 'TournamentEdge';
  cursor: Scalars['String']['output'];
  node: Array<Tournament>;
};

export enum TournamentGameStatus {
  Ended = 'ENDED',
  Started = 'STARTED',
  Waiting = 'WAITING'
}

export type TournamentStep = {
  __typename?: 'TournamentStep';
  configuration: StepConfiguration;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  status: StepStatus;
  teamScores: Array<TournamentTeamScore>;
  tournament: Tournament;
  tournamentId: Scalars['ID']['output'];
  type: StepType;
  updatedAt: Scalars['DateTime']['output'];
};

export type TournamentStepGroup = {
  __typename?: 'TournamentStepGroup';
  configuration: GroupConfiguration;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rounds: Array<TournamentStepGroupRound>;
  tournamentStepId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TournamentStepGroupRound = {
  __typename?: 'TournamentStepGroupRound';
  configuration: RoundConfiguration;
  createdAt: Scalars['DateTime']['output'];
  games: Array<TournamentStepGroupRoundGame>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  tournamentStepGroupId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TournamentStepGroupRoundGame = {
  __typename?: 'TournamentStepGroupRoundGame';
  configuration: GameConfiguration;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  losingGameId?: Maybe<Scalars['ID']['output']>;
  matches: Array<TournamentStepGroupRoundGameMatch>;
  order: Scalars['Float']['output'];
  status: TournamentGameStatus;
  tournamentStepGroupRoundId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  winningGameId?: Maybe<Scalars['ID']['output']>;
};

export type TournamentStepGroupRoundGameMatch = {
  __typename?: 'TournamentStepGroupRoundGameMatch';
  configuration: MatchConfiguration;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  order: Scalars['Float']['output'];
  status: MatchStatus;
  tournamentStepGroupRoundGameId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TournamentStepGroupRoundGameMatchEdge = {
  __typename?: 'TournamentStepGroupRoundGameMatchEdge';
  cursor: Scalars['String']['output'];
  node: Array<TournamentStepGroupRoundGameMatch>;
};

export type TournamentStepGroupRoundGameMatchScore = {
  __typename?: 'TournamentStepGroupRoundGameMatchScore';
  createdAt: Scalars['DateTime']['output'];
  matchId: Scalars['ID']['output'];
  score: Scalars['Float']['output'];
  status: MatchScoreStatus;
  teamId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TournamentStepGroupRoundGameMatchScoreEdge = {
  __typename?: 'TournamentStepGroupRoundGameMatchScoreEdge';
  cursor: Scalars['String']['output'];
  node: Array<TournamentStepGroupRoundGameMatchScore>;
};

export type TournamentStepGroupRoundGameMatchScores = {
  __typename?: 'TournamentStepGroupRoundGameMatchScores';
  edges: Array<TournamentStepGroupRoundGameMatchScoreEdge>;
  nodes: Array<TournamentStepGroupRoundGameMatchScore>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type TournamentStepGroupRoundGameMatches = {
  __typename?: 'TournamentStepGroupRoundGameMatches';
  edges: Array<TournamentStepGroupRoundGameMatchEdge>;
  nodes: Array<TournamentStepGroupRoundGameMatch>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type TournamentTeam = {
  __typename?: 'TournamentTeam';
  createdAt: Scalars['DateTime']['output'];
  customFields: Array<PropertyConfigWithValue>;
  id: Scalars['ID']['output'];
  managerId?: Maybe<Scalars['ID']['output']>;
  members: Array<TournamentTeamMemberDto>;
  name: Scalars['String']['output'];
  status: TournamentTeamStatus;
  tag: Scalars['String']['output'];
  teamId?: Maybe<Scalars['ID']['output']>;
  tournament: Tournament;
  tournamentId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type TournamentTeamCustomFieldsArgs = {
  properties?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TournamentTeamEdge = {
  __typename?: 'TournamentTeamEdge';
  cursor: Scalars['String']['output'];
  node: Array<TournamentTeam>;
};

export type TournamentTeamMemberDto = {
  __typename?: 'TournamentTeamMemberDto';
  createdAt: Scalars['DateTime']['output'];
  playerProfileId: Scalars['ID']['output'];
  status: TournamentTeamMemberStatus;
  team: TournamentTeam;
  tournamentTeamId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TournamentTeamMemberEdge = {
  __typename?: 'TournamentTeamMemberEdge';
  cursor: Scalars['String']['output'];
  node: Array<TournamentTeamMemberDto>;
};

export enum TournamentTeamMemberStatus {
  Accepted = 'ACCEPTED',
  Awaiting = 'AWAITING',
  Denied = 'DENIED'
}

export type TournamentTeamScore = {
  __typename?: 'TournamentTeamScore';
  score: Scalars['Float']['output'];
  team: TournamentTeam;
  teamId: Scalars['ID']['output'];
};

export enum TournamentTeamStatus {
  AwaitingForPayment = 'AWAITING_FOR_PAYMENT',
  AwaitingForPresenceConfirmation = 'AWAITING_FOR_PRESENCE_CONFIRMATION',
  Confirmed = 'CONFIRMED',
  Denied = 'DENIED',
  NotAttending = 'NOT_ATTENDING',
  NotValid = 'NOT_VALID',
  Registered = 'REGISTERED'
}

export type TournamentTeams = {
  __typename?: 'TournamentTeams';
  edges: Array<TournamentTeamEdge>;
  nodes: Array<TournamentTeam>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type Tournaments = {
  __typename?: 'Tournaments';
  edges: Array<TournamentEdge>;
  nodes: Array<Tournament>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type TournamentsQuery = {
  filterByPlayers?: InputMaybe<Array<TournamentsQueryPlayerIdWithTeamStatus>>;
  /** Order by type */
  orderBy?: TournamentsQueryOrderBy;
  /** Order direction */
  orderDirection?: OrderDirection;
  /** Show not visible tournaments, only usable if the user has the correct permission */
  showNotVisible?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by tournament status */
  status?: TournamentsQueryStatus;
};

export enum TournamentsQueryOrderBy {
  EndAt = 'END_AT',
  RegistrationsEndAt = 'REGISTRATIONS_END_AT',
  RegistrationsStartAt = 'REGISTRATIONS_START_AT',
  StartAt = 'START_AT'
}

export type TournamentsQueryPlayerIdWithTeamStatus = {
  memberStatus: TournamentTeamMemberStatus;
  /** Player id */
  playerId: Scalars['ID']['input'];
  /** Team status */
  teamStatus: TournamentTeamStatus;
};

export enum TournamentsQueryStatus {
  All = 'ALL',
  Ended = 'ENDED',
  RegistrationsClosed = 'REGISTRATIONS_CLOSED',
  RegistrationsEnded = 'REGISTRATIONS_ENDED',
  RegistrationsOpen = 'REGISTRATIONS_OPEN',
  Started = 'STARTED'
}

export type UpdateLeaderboardInput = {
  /** The description of the leaderboard */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the leaderboard */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLeaderboardSeasonBucketInput = {
  minScore?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  scoreCalculationType?: InputMaybe<LeaderboardScoreCalculationType>;
};

export type UpdateLeaderboardSeasonInput = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  startingScore?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateMatchScoreInput = {
  forcedScoreValue?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<MatchScoreStatus>;
  teamId: Scalars['ID']['input'];
  variables?: InputMaybe<Array<UpdateTournamentMatchScoreVariablesInput>>;
};

export type UpdateMePlayerInput = {
  customFields: Array<PlayerCustomFieldInput>;
  description: Scalars['String']['input'];
  profilePicUrl?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type UpdateOrganizationAppInput = {
  consentUrl: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  loginUrl: Scalars['String']['input'];
  logoutRedirectUrls: Array<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  redirectUrls: Array<Scalars['String']['input']>;
  requiresConsent: Scalars['Boolean']['input'];
  secret?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationIdentityProvider = {
  allowLogin: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  icon?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  oauth2Configuration?: InputMaybe<OAuthClientConfigurationInput>;
  openidConfiguration?: InputMaybe<OpenidConfigurationInput>;
};

export type UpdateOrganizationInput = {
  configuration: OrganizationConfigurationInput;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdatePlatformInput = {
  configuration?: InputMaybe<PlatformConfigurationInput>;
  name: Scalars['String']['input'];
};

export type UpdatePlatformTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  screenshotUrl?: InputMaybe<Scalars['URL']['input']>;
};

export type UpdatePlayerInput = {
  customFields?: InputMaybe<Array<PlayerCustomFieldInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  organizationCustomId?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  profilePicUrl?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTournamentConfigurationInput = {
  /** Custom fields that are defined on each teams */
  customFields?: InputMaybe<Array<PropertyInput>>;
  /** Condition rules to register to the tournament */
  registrationConditions?: InputMaybe<RegistrationConditionsInput>;
  /** Maximum team size to register to the tournament */
  teamMaxSize?: InputMaybe<Scalars['Float']['input']>;
  /** Minimum team size to register to the tournament */
  teamMinSize?: InputMaybe<Scalars['Float']['input']>;
  /** Status of the team after registration, REGISTERED is the default state, AWAITING_FOR_PRESENCE_CONFIRMATION means that the team has been validated and is waiting for presence confirmation, AWAITING_FOR_PAYMENT means that the team has to pay to be validated */
  teamStatusAfterRegistration?: InputMaybe<TeamStatusAfterRegistration>;
  /** Maximum number of registered teams */
  teamsCount?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<ConfigurationType>;
};

export type UpdateTournamentConfigurationOrImportFromIdInput = {
  /** Tournament configuration */
  configuration?: InputMaybe<UpdateTournamentConfigurationInput>;
  /** Import configuration from a saved configuration */
  fromId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateTournamentInput = {
  /** Tournament configuration */
  configuration?: InputMaybe<UpdateTournamentConfigurationOrImportFromIdInput>;
  /** Tournament description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Tournament end date */
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Tournament registrations end date */
  endRegistrationsAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Tournament start date */
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Tournament registrations start date */
  startRegistrationsAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Tournament title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Tournament visibility date, the tournament will be visible from this date in the tournaments list */
  visibleAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateTournamentMatchScoreVariablesInput = {
  formulaName: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type UpdateTournamentTeamInput = {
  customFields?: InputMaybe<Array<PropertyValueInput>>;
  name: Scalars['String']['input'];
  tag: Scalars['String']['input'];
};

export type UpdateWebhookInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<Array<WebhookEventType>>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UserConnectionEvent = {
  __typename?: 'UserConnectionEvent';
  accountId?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  playerId?: Maybe<Scalars['ID']['output']>;
  serverId: Scalars['ID']['output'];
  sessionId: Scalars['ID']['output'];
};

export type UserDisconnectEvent = {
  __typename?: 'UserDisconnectEvent';
  accountId?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  playerId?: Maybe<Scalars['ID']['output']>;
  serverId: Scalars['ID']['output'];
  sessionId: Scalars['ID']['output'];
};

export type UserGroup = {
  __typename?: 'UserGroup';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  managerId: Scalars['ID']['output'];
  members: Array<UserGroupMember>;
  organizationId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserGroupEdge = {
  __typename?: 'UserGroupEdge';
  cursor: Scalars['String']['output'];
  node: Array<UserGroup>;
};

export type UserGroupMember = {
  __typename?: 'UserGroupMember';
  createdAt: Scalars['DateTime']['output'];
  groupId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  playerProfileId: Scalars['ID']['output'];
  status: UserGroupMemberStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export enum UserGroupMemberStatus {
  Accepted = 'ACCEPTED',
  Denied = 'DENIED',
  Pending = 'PENDING'
}

export type UserGroups = {
  __typename?: 'UserGroups';
  edges: Array<UserGroupEdge>;
  nodes: Array<UserGroup>;
  pageInfo: ResponsePageInfo;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type UserGroupsQueryInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type UsersConnectedEvent = {
  __typename?: 'UsersConnectedEvent';
  usersConnected: Array<UserConnectionEvent>;
};

export type UsersDisconnectedEvent = {
  __typename?: 'UsersDisconnectedEvent';
  usersDisconnected: Array<UserDisconnectEvent>;
};

export type Variable = {
  __typename?: 'Variable';
  defaultValue: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type VariableInput = {
  defaultValue: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type Webhook = {
  __typename?: 'Webhook';
  active: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  events: Array<WebhookEventType>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  secret: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type WebhookEventConfiguration = {
  __typename?: 'WebhookEventConfiguration';
  /** If this property is available, it means that the event is a "RPC" event and that an answer is expected from the webhook called */
  answerPayload?: Maybe<WebhookEventConfigurationProperties>;
  description: Scalars['String']['output'];
  payload: WebhookEventConfigurationProperties;
  type: WebhookEventType;
};

export type WebhookEventConfigurationProperties = {
  __typename?: 'WebhookEventConfigurationProperties';
  array: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  example?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  optional: Scalars['Boolean']['output'];
  properties?: Maybe<Array<WebhookEventConfigurationProperties>>;
  type: Scalars['String']['output'];
};

export enum WebhookEventType {
  TournamentTeamAttendanceUpdated = 'TOURNAMENT_TEAM_ATTENDANCE_UPDATED',
  TournamentTeamConfirmationUpdated = 'TOURNAMENT_TEAM_CONFIRMATION_UPDATED',
  TournamentTeamDeleted = 'TOURNAMENT_TEAM_DELETED',
  TournamentTeamDeletedAdmin = 'TOURNAMENT_TEAM_DELETED_ADMIN',
  TournamentTeamStatusUpdated = 'TOURNAMENT_TEAM_STATUS_UPDATED'
}
