import { Title } from "@mantine/core";
import { graphql } from "gql.tada";
import { AutoDataTable } from "../../../components/AutoDataTable";
import { gql } from "@apollo/client";

const VENUES_QUERY = graphql(`
  query eventVenues($cursor: String, $count: Float) {
    eventVenues(page: { after: $cursor, first: $count }) {
      nodes {
        id
        name
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const Venues = () => {
  return (
    <>
      <Title>Venues</Title>
      <AutoDataTable
        createButtonText="Create a new venue"
        deleteMutation={gql`
          mutation ($id: ID!) {
            eventVenueDelete(id: $id) {
              id
            }
          }
        `}
        query={VENUES_QUERY}
        columns={[
          {
            accessor: "name",
            title: "Name",
          },
        ]}
      />
    </>
  );
};
